import { action, computed, observable, runInAction } from "mobx";

import { compareDatesOrUndefinedPredicate, DateTime } from "@bps/utils";
import { sharePendingPromise } from "@libs/decorators/sharePendingPromise.ts";
import {
  ClaimAdjustmentDto,
  ClaimDiagnosisDto,
  ClaimDto,
  ClaimStatuses
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { OrgUnit } from "@stores/core/models/OrgUnit.ts";
import { User } from "@stores/core/models/User.ts";
import type { Contact } from "@stores/practice/models/Contact.ts";
import { mergeModel } from "@stores/utils/store.utils.ts";

import { ClaimAdjustment } from "./ClaimAdjustment.ts";
import { ClaimReview } from "./ClaimReview.ts";
import { Schedule } from "./Schedule.ts";

export class Claim extends Model<ClaimDto> {
  @observable patient: Contact | undefined;

  claimAdjustmentMap = observable.map<string, ClaimAdjustment>();

  @observable
  private _claimReview: ClaimReview | undefined;

  private _user: User | undefined;

  private _sendAccForm: string | undefined;

  private _claimSchedules: Schedule[] | undefined;

  constructor(
    private root: IRootStore,
    dto: ClaimDto
  ) {
    super(dto);
  }

  @action
  public mergeClaimAdjustment = (dto: ClaimAdjustmentDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new ClaimAdjustment(this.root, dto),
      map: this.claimAdjustmentMap
    });
  };

  public populateClaimAdjustmentMap() {
    this.dto.claimAdjustments?.map(this.mergeClaimAdjustment);
  }

  public deleteClaimAdjustmentFromMap(claimAdjustmentId: string) {
    runInAction(() => {
      this.claimAdjustmentMap.delete(claimAdjustmentId);
    });
  }

  @computed
  get claimAppointments() {
    return (
      Array.from(this.root.acc.claimAppointmentMap.values()).filter(
        ca => ca.claimId === this.id
      ) || []
    );
  }

  @computed
  get currentDiagnoses() {
    return this.dto.currentDiagnoses ?? [];
  }

  @computed
  get patientDateOfBirth() {
    return DateTime.fromISO(this.dto.patientDateOfBirth);
  }

  @computed
  get patientFullName() {
    return `${this.dto.patientFirstName} ${this.dto.patientLastName}`;
  }

  @computed
  get isClaimStatusesAllowToPrintOnlineForm() {
    return (
      this.claimStatus === ClaimStatuses.Incomplete ||
      this.claimStatus === ClaimStatuses.Ready ||
      this.claimStatus === ClaimStatuses.Queued ||
      this.claimStatus === ClaimStatuses.Declined ||
      this.claimStatus === ClaimStatuses.Error
    );
  }

  @computed
  get isClaimStatusesAllowToSendOnlineForm() {
    return (
      this.claimStatus === ClaimStatuses.Incomplete ||
      this.claimStatus === ClaimStatuses.Ready ||
      this.claimStatus === ClaimStatuses.Declined ||
      this.claimStatus === ClaimStatuses.Error
    );
  }

  @computed
  get isNotVerifiedOrNotAvailable() {
    return (
      this.claimStatus === ClaimStatuses.NotVerified ||
      this.claimStatus === ClaimStatuses.NotAvailable
    );
  }
  @computed
  get isNotIncompleteOrReady() {
    return (
      this.claimStatus !== ClaimStatuses.Incomplete &&
      this.claimStatus !== ClaimStatuses.Ready
    );
  }

  @computed
  get isNotDeclinedOrError() {
    return (
      this.claimStatus !== ClaimStatuses.Declined &&
      this.claimStatus !== ClaimStatuses.Error
    );
  }

  @computed
  get private() {
    return this.dto.private;
  }

  @computed
  get claimDiagnosis() {
    return this.dto.claimDiagnosis;
  }

  @computed
  get referralDiagnosis() {
    return this.dto.referralDiagnosis;
  }

  @computed
  get discharged() {
    return this.dto.discharged;
  }

  @computed
  get appointmentVisits() {
    return this.dto.appointmentVisits;
  }

  @computed
  get referralDate() {
    return DateTime.fromISO(this.dto.referralDate);
  }

  @computed
  get referralProvider() {
    return this.dto.referralProvider;
  }

  @computed
  get claimNumber() {
    return this.dto.claimNumber;
  }

  @computed
  get providerFullName() {
    return `${this.dto.providerFirstName || ""} ${
      this.dto.providerLastName || ""
    }`;
  }

  @computed
  get providerFirstName() {
    return this.dto.providerFirstName || "";
  }

  @computed
  get providerLastName() {
    return this.dto.providerLastName || "";
  }

  @computed
  get claimStatus() {
    return this.dto.claimStatusCode;
  }

  private invoiceableStatuses: string[] = [
    ClaimStatuses.Discharged,
    ClaimStatuses.Accepted,
    ClaimStatuses.Pending,
    ClaimStatuses.Held,
    ClaimStatuses.NotAvailable,
    ClaimStatuses.NotVerified
  ];

  private disabledStatuses: string[] = [
    ClaimStatuses.Accepted,
    ClaimStatuses.Discharged,
    ClaimStatuses.Held,
    ClaimStatuses.NotAvailable,
    ClaimStatuses.Pending,
    ClaimStatuses.Queued
  ];

  @computed
  get readyToInvoice() {
    return (
      this.claimStatus && this.invoiceableStatuses.includes(this.claimStatus)
    );
  }

  @computed
  get canEdit() {
    return (
      !!this.claimStatus && !this.disabledStatuses.includes(this.claimStatus)
    );
  }

  loadClaimSchedules = async () => {
    this._claimSchedules = await this.root.acc.getSchedules({
      claimId: this.id
    });
  };

  @computed
  get canUpdateClaimNumber() {
    if (!this.claimNumber?.trim()) {
      return false;
    }

    const hasPayment =
      this._claimSchedules?.some(s => s.isPaidOrPartiallyPaid) ?? false;

    const hasPermission =
      this.referralIn ||
      this.root.core.hasPermissions(
        Permission.AccUpdateNonReferredClaimNumberAllowed
      ) ||
      false;

    const claimStatusIsAcceptedOrAccredited =
      this.claimStatus === ClaimStatuses.Accepted ||
      this.claimStatus === ClaimStatuses.Accredited;

    return hasPermission && !hasPayment && claimStatusIsAcceptedOrAccredited;
  }

  @computed
  get previousClaimStatus() {
    return this.dto.previousClaimStatusCode;
  }

  @computed
  get patientId() {
    return this.dto.patientId;
  }

  @computed
  get providerId() {
    return this.dto.providerId;
  }

  @computed
  get providerTypeCode() {
    return this.dto.providerTypeCode;
  }

  @computed
  get provider() {
    return this.providerId
      ? this.root.practice.providersMap.get(this.providerId)
      : undefined;
  }

  @computed
  get contractType() {
    return this.root.practice.ref.accProviderContractTypes.values.find(
      ref =>
        ref.providerTypeCode === this.providerTypeCode &&
        this.provider?.contractTypes?.some(code => code === ref.code)
    );
  }

  @computed
  get patientDeclaration() {
    return DateTime.fromISO(this.dto.patientDeclaration);
  }

  @computed
  get providerDeclaration() {
    return DateTime.fromISO(this.dto.providerDeclaration);
  }

  @computed
  get accidentDate() {
    return DateTime.fromISO(this.dto.accidentDate);
  }

  @computed
  get initialConsultDate() {
    return DateTime.fromISO(this.dto.initialConsultDate);
  }

  @computed
  get isPostInitialConsultDate() {
    return !!this.initialConsultDate?.isBeforeToday;
  }

  @computed
  get isDischarged() {
    return !!this.dto.discharged;
  }

  @computed
  get primaryDiagnosis(): ClaimDiagnosisDto | undefined {
    if (!!this.currentDiagnoses.length) {
      return this.currentDiagnoses[0];
    } else if (!!this.claimDiagnosis?.length) {
      return this.claimDiagnosis[0];
    } else {
      return undefined;
    }
  }

  @computed
  get referralIn(): boolean {
    return !!this.dto.referralIn;
  }

  @computed
  get referralDetails() {
    return this.dto.referralDetails;
  }

  @computed
  get errorMessageList() {
    return this.dto.errorMessage ?? [];
  }

  @computed
  get billed() {
    return this.dto.billed;
  }

  @computed
  get allocated() {
    return this.dto.allocated;
  }

  @computed
  get extensions() {
    return this.dto.extensions;
  }

  @computed
  get booked() {
    return this.dto.booked;
  }

  @computed
  get maxVisits() {
    return (this.dto.allocated ?? 0) + (this.dto.extensions ?? 0);
  }

  @computed
  get totalRemainingAppointments() {
    return this.dto.totalRemainingAppointments;
  }

  @computed
  get employerName() {
    return this.dto.employerName;
  }

  @computed
  get causeOfAccident() {
    return this.dto.causeOfAccident;
  }

  @action
  setPatient = (patient: Contact | undefined) => {
    this.patient = patient;
  };

  get user() {
    return this._user;
  }

  set user(user: User | undefined) {
    this._user = user;
  }

  @computed
  get insurerContactId() {
    return this.dto.insurerContactId;
  }

  @computed
  get insurerName() {
    return this.dto.insurerName;
  }

  get sendAccForm() {
    return this._sendAccForm;
  }

  set sendAccForm(value: string | undefined) {
    this._sendAccForm = value;
  }

  @computed
  get initialCalendarEvent(): CalendarEvent | undefined {
    const ceIds = this.claimAppointments.map(x => x.calendarEventId);
    return Array.from(this.root.booking.calendarEventsMap.values())
      .filter(ca => ceIds.includes(ca.id))
      .sort((a, b) =>
        compareDatesOrUndefinedPredicate(a.startDateTime, b.startDateTime)
      )[0];
  }

  get createdDate() {
    return DateTime.fromISO(this.dto.changeLog?.createdDate);
  }

  @computed
  get claimAdjustments() {
    return Array.from(this.claimAdjustmentMap.values());
  }

  @computed
  get claimReview() {
    return this._claimReview;
  }

  @computed
  get isImported() {
    return this.dto.isImported;
  }

  @computed
  get providerTypeAllowAcc45() {
    return this.providerTypeCode
      ? this.root.practice.ref.accProviderTypes.values.find(
          p => p.code === this.providerTypeCode
        )?.allowAcc45
      : undefined;
  }

  @computed
  public get isViewMode() {
    if (!this.root.core.hasPermissions(Permission.ClaimWrite)) return true;

    if (!this.claimStatus) return false;

    if (this.private) {
      return true;
    }

    const status = this.root.acc.ref.claimStatuses.values.find(
      s => s.code === this.claimStatus
    );
    return !status?.claimUpdateAllowed;
  }

  @action
  public setClaimReview(claimReview?: ClaimReview) {
    this._claimReview = claimReview;
  }

  loadPatient = async () => {
    if (this.patientId) {
      const patient = await this.root.practice.getContact(this.patientId);
      this.setPatient(patient);
    }
  };

  @sharePendingPromise()
  async loadProvider() {
    if (this.dto.providerId) {
      await Promise.resolve([
        this.root.practice.getProvider(this.dto.providerId),
        this.root.practice.ref.accProviderContractTypes.load()
      ]);
    }
  }

  loadUser = async () => {
    if (this.dto.providerId) {
      this.user = await this.root.core.getUser(this.dto.providerId);
    }
  };

  @sharePendingPromise()
  loadClaimAppointments = async (options?: { ignoreCache?: boolean }) => {
    if (!this.claimAppointments.length || options?.ignoreCache) {
      await this.root.acc.getClaimAppointmentDtos({
        claimIds: [this.id]
      });
    }

    return this.claimAppointments;
  };

  @action
  loadClaimReview = async () => {
    const claimReview = await this.root.acc.getClaimReviewByClaimId(this.id);
    this.setClaimReview(claimReview);
  };

  loadCalendarEvent = async () => {
    await this.loadClaimAppointments();
    if (this.claimAppointments.length > 0) {
      const calendarEventIds = this.claimAppointments.map(
        ca => ca.calendarEventId
      );

      await this.root.booking.getCalendarEvents({ calendarEventIds });
    }
  };

  // ensure accProviderContractTypes and provider has been loaded before using
  get providerContactClass() {
    return this.root.practice.ref.accProviderContractTypes.values.find(
      ref =>
        ref.providerTypeCode === this.providerTypeCode &&
        this.provider?.contractTypes?.some(code => code === ref.code)
    );
  }

  @computed
  get isOnHold() {
    if (!this.root.core.hasPermissions(Permission.OnHoldClaimAllowed)) {
      return false;
    }

    return this.dto.isOnHold;
  }

  // getOrgUnitData is to be used when editing a claim to get the up-to-date data from the org units
  //  The maps should be loaded already using fetchOrgUnits prop on ClaimDataFetcher
  //  The data may not already exist on the claim because no calendar event will be linked when the claim is originally created
  getOrgUnitData = (): Pick<
    ClaimDto,
    | "hpiFacilityNumber"
    | "hpiOrganisationNumber"
    | "providerAddress"
    | "practiceName"
  > => {
    let orgUnit: OrgUnit | undefined;

    if (!this.root.core.hasMultiLocationOrgUnit) {
      orgUnit = this.root.core.location;
    } else if (this.initialCalendarEvent) {
      orgUnit = this.root.core.orgUnitsMap.get(
        this.initialCalendarEvent.orgUnitId
      );
    }

    const pracOrgUnit = orgUnit
      ? this.root.practice.pracOrgUnitMap.get(orgUnit.id)
      : undefined;

    const parentPracOrgUnit = this.root.core.location.parentOrgUnitId
      ? this.root.practice.pracOrgUnitMap.get(
          this.root.core.location.parentOrgUnitId
        )
      : undefined;

    return {
      hpiFacilityNumber:
        this.dto.hpiFacilityNumber ||
        pracOrgUnit?.nzOrgUnitIdentifier?.hpiFacilityId,
      hpiOrganisationNumber:
        this.dto.hpiOrganisationNumber ||
        parentPracOrgUnit?.nzOrgUnitIdentifier?.hpiOrganisationId,
      practiceName:
        this.dto.practiceName ||
        parentPracOrgUnit?.nzOrgUnitIdentifier?.hpiOrganisationName,
      providerAddress: this.dto.providerAddress || orgUnit?.firstAddress
    };
  };
}
