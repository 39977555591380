import { action, computed, observable, runInAction } from "mobx";

import { newGuid } from "@bps/utils";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { sharePendingPromise } from "@libs/decorators/sharePendingPromise.ts";
import {
  CorrespondenceType,
  DocumentContentType,
  DocumentTabStatus,
  SubmitActionCode,
  TemplateTypeCode,
  TemplateVisibility,
  TemplateWriterTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  AutofillDto,
  NonCustomTemplateName,
  TemplateArgs,
  TemplateDataModelDataDto,
  TemplateDataModelDto,
  TemplateDto,
  TemplateRenderDto,
  TemplateRenderOptions
} from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { IDocumentGateway } from "@libs/gateways/document/DocumentGateway.interface.ts";
import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import type { Store } from "@stores/types/store.type.ts";
import { mergeModel } from "@stores/utils/store.utils.ts";

import { DocumentRef } from "./DocumentRef.ts";
import { Template } from "./models/Template.ts";

export class DocumentStore implements Store<DocumentStore, DocumentRef> {
  constructor(private gateway: IDocumentGateway) {
    this.ref = new DocumentRef(this.gateway);
  }

  afterAttachRoot() {
    this.root.acc.hub.onEntityEvent(
      Entity.Template,
      this.updateOnTemplateDelete
    );
  }

  root: IRootStore;
  ref: DocumentRef;

  @action private updateOnTemplateDelete = async (message: EntityEventData) => {
    if (message.id) {
      if (message.action === EventAction.Delete) {
        this.closeTemplate(message.id);
      }
      this.getTemplate(message.id, { ignoreCache: true });
    }
  };

  private get routing() {
    return this.root.routing;
  }

  get notification() {
    return this.root.notification;
  }

  get clinical() {
    return this.root.clinical;
  }

  get core() {
    return this.root.core;
  }
  templateMap = observable.map<string, Template>();

  @observable
  showTemplateManagementModal: boolean | undefined;

  @observable
  openedTemplateTabs = observable(new Array<TemplateWriterTab>());

  @observable
  autofills = observable(new Array<AutofillDto>());

  isFromAutofillMgmt: boolean = false;

  @action
  public setShowTemplateManagementModal(
    value: boolean,
    isFromAutofillMgmt?: boolean
  ) {
    this.showTemplateManagementModal = value;
    this.isFromAutofillMgmt = isFromAutofillMgmt ?? false;
  }

  @observable
  selectedTemplateId: string | undefined;

  @action
  setSelectedTemplateId = (templateId: string | undefined) => {
    this.selectedTemplateId = templateId;
  };

  @action
  public mergeTemplate = (dto: TemplateDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new Template(this.root.core, dto),
      map: this.templateMap
    });
  };

  getTemplates = async (args: TemplateArgs): Promise<Template[]> => {
    const dtos = await this.gateway.getTemplates(args);
    return dtos.map(this.mergeTemplate);
  };

  getAutofills = async (): Promise<AutofillDto[]> => {
    if (this.autofills.length > 0) {
      return this.autofills;
    }

    const dtos = await this.gateway.getAutofills();

    runInAction(() => {
      this.autofills.replace(dtos);
    });
    return dtos;
  };

  @computed
  get activeAutofills() {
    const reducedAutoFills = this.autofills.reduce(
      (total: AutofillDto[], currentItem: AutofillDto) => {
        const isDuplicateAutoShortCut =
          this.autofills.filter(x => x.shortcut === currentItem.shortcut)
            .length > 1;

        if (
          !isDuplicateAutoShortCut ||
          (currentItem.documentStatus === SubmitActionCode.PublishToSelfCode &&
            currentItem?.changeLog?.createdBy === this.core.user?.username)
        ) {
          total.push(currentItem);
        }

        return total;
      },
      []
    );
    return reducedAutoFills;
  }

  getAllTemplates = async (): Promise<Template[]> => {
    const dtos = await this.gateway.getAllTemplates();
    return dtos.map(this.mergeTemplate);
  };

  @action
  deleteTemplate = async (
    reasonForDelete?: string,
    reasonForDeleteComment?: string
  ): Promise<void> => {
    const templateId = this.selectedTemplateId;
    if (templateId) {
      await this.gateway.deleteTemplate(templateId, {
        reasonForDelete,
        reasonForDeleteComment
      });
      this.closeTemplate(templateId);
      runInAction(() => {
        this.templateMap.delete(templateId);
        const filteredAutofills = this.autofills.filter(
          x => x.id !== templateId
        );
        this.autofills.replace(filteredAutofills);
        this.setSelectedTemplateId(undefined);
      });
    }
  };

  @sharePendingPromise()
  getTemplate = async (
    templateId: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ): Promise<Template> => {
    if (!options.ignoreCache) {
      const template = this.templateMap.get(templateId);

      if (template) {
        return template;
      }
    }

    return this.gateway.getTemplate(templateId).then(this.mergeTemplate);
  };

  generateTemplateDto(props: {
    documentType: CorrespondenceType;
    name?: string;
    documentStatus?: string;
    isAdmin: boolean;
    isClinical: boolean;
    shortcut?: string;
    content?: string;
  }) {
    const dto: TemplateDto = {
      id: newGuid(),
      name: props.name ?? props.documentType,
      documentType: props.documentType,
      content: props.content ?? "",
      templateTypeCode: TemplateTypeCode.Clinical,
      templateFormat: DocumentContentType.Sfdt,
      isCustom: true,
      isSystem: false,
      eTag: "",
      isNew: true,
      isLetterhead: false,
      documentStatus: props.documentStatus,
      isAdmin: props.isAdmin,
      isClinical: props.isClinical,
      shortcut: props.shortcut
    };

    this.mergeTemplate(dto);
    return dto;
  }

  createWriterTemplate = async (
    documentType: CorrespondenceType,
    values: {
      documentTitle?: string;
      documentStatus?: string;
      shortcut?: string;
      documentContent?: string;
    },
    options: {
      visibility: string;
      writerTemplate?: Template;
      isSaveAsCopy?: boolean;
      isFromTemplateWriter?: boolean;
    }
  ) => {
    const { visibility, writerTemplate, isSaveAsCopy, isFromTemplateWriter } =
      options;

    const documentTitle = values.documentTitle;
    let renderedContent: TemplateRenderDto | undefined;

    if (writerTemplate) {
      const renderOptions: TemplateRenderOptions = {
        skipMerge: true
      };
      renderedContent = await this.renderTemplate(
        writerTemplate.id,
        renderOptions
      );
    }

    const dto = this.generateTemplateDto({
      documentType,
      name: documentTitle,
      documentStatus: values.documentStatus,
      isAdmin:
        visibility === TemplateVisibility.Admin ||
        visibility === TemplateVisibility.Both,
      isClinical:
        visibility === TemplateVisibility.Clinical ||
        visibility === TemplateVisibility.Both,
      shortcut: values.shortcut,
      content: values.documentContent ?? renderedContent?.content
    });
    if (isSaveAsCopy && isFromTemplateWriter) {
      await this.createTemplate(dto);
    } else {
      const template = await this.getTemplate(dto.id);
      template.setRenderedContent(renderedContent?.content ?? "");

      this.setActiveTemplateTab({
        documentId: dto.id,
        documentTabStatus: DocumentTabStatus.New
      });
      this.setShowTemplateManagementModal(false);
    }
  };

  createTemplate = async (template: TemplateDto): Promise<Template> => {
    const createdTemplate = await this.gateway
      .createTemplate(template)
      .then(this.mergeTemplate);
    if (createdTemplate.shortcut) {
      const autofill = this.convertTemplateToAutofill(createdTemplate);
      if (autofill) {
        runInAction(() => {
          this.autofills.push(autofill);
          this.root.notification.success("Autofill created successfully");
        });
      }
    }
    return createdTemplate;
  };

  editTemplate = async (template: TemplateDto): Promise<Template> => {
    const updatedTemplate = await this.gateway
      .editTemplate(template)
      .then(this.mergeTemplate);

    if (updatedTemplate && updatedTemplate.shortcut) {
      runInAction(() => {
        const autofill = this.convertTemplateToAutofill(updatedTemplate);
        if (autofill) {
          const index = this.autofills.findIndex(x => x.id === autofill.id);
          this.autofills[index] = autofill;
          this.root.notification.success("Autofill updated successfully");
        }
      });
    }

    return updatedTemplate;
  };

  private convertTemplateToAutofill = (template: Template) => {
    const shortcut = template.shortcut;
    if (shortcut) {
      const autofill: AutofillDto = {
        id: template.id,
        name: template.name,
        isClinical: template.dto.isClinical,
        isAdmin: template.dto.isAdmin,
        shortcut,
        documentStatus: template.documentStatus,
        changeLog: template.changeLog
      };
      return autofill;
    }
    return undefined;
  };

  renderTemplate = async (
    templateId: string,
    options?: TemplateRenderOptions
  ): Promise<TemplateRenderDto> => {
    return await this.gateway.renderTemplate(templateId, {
      context: options?.context ?? {},
      skipMerge: options?.skipMerge,
      isPreview: options?.isPreview,
      letterheadId: options?.letterheadId,
      includeDefaultBody: options?.includeDefaultBody,
      parameters: options?.parameters,
      contentType: options?.contentType
    });
  };

  getTemplateDataModel = async (
    templateType: string,
    options: TemplateRenderOptions
  ): Promise<TemplateDataModelDto> => {
    return await this.gateway.getTemplateDataModel(templateType, options);
  };

  getTemplateDataModelData = async (
    templateType: string,
    propertyPath: string,
    options: TemplateRenderOptions
  ): Promise<TemplateDataModelDataDto> => {
    return await this.gateway.getTemplateDataModelData(
      templateType,
      propertyPath,
      options
    );
  };

  @computed
  get activeTemplateTab() {
    let documentTab: TemplateWriterTab | undefined;

    const id = this.routing.match(routes.documentWriter.template)?.params.id;

    if (id) {
      const document = this.templateMap.get(id);

      if (document) {
        documentTab = this.openedTemplateTabs.find(x => x.documentId === id);
      }
    }

    return documentTab;
  }

  @action
  async setActiveTemplateTab(templateTab: TemplateWriterTab | undefined) {
    if (templateTab) {
      let tab: TemplateWriterTab = templateTab;
      if (this.activeTemplateTab?.documentId === templateTab.documentId) {
        return;
      }

      const id = templateTab.documentId;
      const mapTab = this.openedTemplateTabs.find(x => x.documentId === id);

      if (mapTab) {
        tab = mapTab;
      } else {
        runInAction(() => {
          this.openedTemplateTabs.push(tab);
        });

        await this.clinical.saveToUserStorage(
          UserStorageKeys.OpenTemplates,
          this.openedTemplateTabs
        );
      }

      const pathname = id
        ? routes.documentWriter.template.path({ id })
        : routes.dashboard.basePath.pattern;

      this.routing.push(
        {
          pathname
        },
        tab
      );
    }
  }

  @action
  closeTemplate(templateId: string) {
    const index = this.openedTemplateTabs.findIndex(
      d => d.documentId === templateId
    );

    const closingActiveTab = this.activeTemplateTab?.documentId === templateId;

    const map = this.openedTemplateTabs[index];
    if (map) {
      runInAction(() => {
        this.openedTemplateTabs.remove(map);
      });

      this.clinical.saveToUserStorage(
        UserStorageKeys.OpenTemplates,
        this.openedTemplateTabs
      );
    }

    if (!closingActiveTab) {
      return;
    }

    this.routing.back();
  }

  async getTemplateByDocumentId(
    documentId: string
  ): Promise<Template | undefined> {
    const template = this.templateMap.get(documentId);

    if (!template) {
      try {
        const dto = await this.gateway.getTemplate(documentId);

        const model = this.mergeTemplate(dto);

        return model;
      } catch (e) {
        this.closeTemplate(documentId);
        this.notification.error(e, {
          messageOverride: "Could not find the related template."
        });
        throw e;
      }
    }
    return template;
  }

  getDefaultPracticeLetterhead = async (): Promise<Template | undefined> => {
    const templates = await this.getTemplates({
      name: NonCustomTemplateName.PracticeLetterhead,
      isLetterhead: true
    });

    const practiceLetterhead = templates.find(template => !template.isCustom);

    return practiceLetterhead;
  };

  @action
  updateTemplateTab = (
    originalTemplateId: string,
    newTemplateId: string,
    onSave: boolean
  ) => {
    const tab = this.openedTemplateTabs.find(
      x => x.documentId === originalTemplateId
    );

    if (tab) {
      if (onSave) {
        tab.documentTabStatus = DocumentTabStatus.Edit;
        tab.newDocumentId = newTemplateId;
      } else {
        tab.documentId = newTemplateId;
        tab.newDocumentId = undefined;
      }

      this.clinical.saveToUserStorage(
        UserStorageKeys.OpenTemplates,
        this.openedTemplateTabs
      );
    }
  };

  @computed
  get documentLists() {
    return Array.from(this.templateMap.values());
  }
}
