import { computed } from "mobx";

import { DateTime, groupBy, isDefined, unique } from "@bps/utils";
import { sharePendingPromise } from "@libs/decorators/sharePendingPromise.ts";
import { Country } from "@libs/enums/country.enum.ts";
import { OutboundCommChannel } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import {
  AddressType,
  CommunicationDto,
  CommunicationType,
  ContactDto,
  ContactType,
  CscDto,
  DvaDto,
  EmployerDto,
  EntitlementDto,
  EntitlementType,
  HealthFundDto,
  HealthProviderDto,
  MedicareDto,
  NhiDto,
  OrganisationContactDto,
  OrganisationRoleDto,
  OrganisationRoleType,
  PatientDto,
  PrivateInsurerDto,
  PublicInsurerDto,
  RelationshipType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import {
  computePersonInitials,
  findAddressValue,
  getOrUndefined
} from "@libs/utils/utils.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { communicationComparerWithPreference } from "@stores/core/models/Communication.ts";
import {
  filterPatientEmployers,
  findCommValue,
  isExpired,
  willExpire
} from "@stores/practice/utils/practice.utils.ts";

export class Contact extends Model<ContactDto> {
  constructor(
    private root: IRootStore,
    dto: ContactDto
  ) {
    super(dto);
  }

  @computed
  get communications() {
    return this.dto.communications
      ? this.dto.communications
          .slice()
          .sort(communicationComparerWithPreference)
      : [];
  }

  @computed
  get primaryCommunication(): CommunicationDto | undefined {
    return this.communications
      .filter(
        x =>
          x.type !== CommunicationType.Fax && x.type !== CommunicationType.Email
      )
      .sort(communicationComparerWithPreference)[0];
  }

  @computed
  get phone(): string | undefined {
    if (this.type === ContactType.Organisation) {
      const phone = findCommValue(this.communications, CommunicationType.Phone);
      return phone || this.mobilePhone || this.afterHoursPhone;
    }

    return (
      this.preferredPhone ||
      this.mobilePhone ||
      this.homePhone ||
      this.workPhone ||
      this.afterHoursPhone
    );
  }

  @computed
  get draftItemsEnabled() {
    return isOrganisation(this.dto) && this.dto.draftItemsEnabled;
  }

  @computed
  get phoneCommunicationsOnly() {
    return this.communications.filter(
      x =>
        x.type !== CommunicationType.Email &&
        x.type !== CommunicationType.Fax &&
        x.type !== CommunicationType.Website
    );
  }

  @computed
  get preferredPhone(): string | undefined {
    const phoneTypes = [
      CommunicationType.Mobile,
      CommunicationType.HomePhone,
      CommunicationType.WorkPhone,
      CommunicationType.AfterHours
    ];
    return this.communications.find(communication => {
      return communication.preferred && phoneTypes.includes(communication.type);
    })?.value;
  }

  @computed
  get homePhone(): string | undefined {
    return findCommValue(this.communications, CommunicationType.HomePhone);
  }

  @computed
  get mobilePhone(): string | undefined {
    return findCommValue(this.communications, CommunicationType.Mobile);
  }

  @computed
  get workPhone(): string | undefined {
    return findCommValue(this.communications, CommunicationType.WorkPhone);
  }

  @computed
  get afterHoursPhone(): string | undefined {
    return findCommValue(this.communications, CommunicationType.AfterHours);
  }

  @computed
  get email(): string | undefined {
    return findCommValue(this.communications, CommunicationType.Email);
  }

  @computed
  get type() {
    return this.dto.type;
  }

  @computed
  get nickName() {
    return this.dto.fullName?.nickName;
  }

  @computed
  get firstName() {
    return this.dto.fullName?.firstName;
  }

  @computed
  get lastName() {
    return this.dto.fullName?.lastName;
  }

  @computed
  get middleName() {
    return this.dto.fullName?.middleName;
  }

  @computed
  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  @computed
  get name() {
    if (this.type === ContactType.Organisation) {
      return this.dto.organisationName ?? "";
    } else {
      return this.nickName
        ? `${this.nickName} (${this.firstName}) ${this.lastName}`
        : `${this.firstName} ${this.lastName}`;
    }
  }

  @computed
  get preferredName() {
    if (this.type === ContactType.Organisation) {
      return this.dto.organisationName;
    } else {
      return this.nickName
        ? `${this.nickName} ${this.lastName}`
        : `${this.firstName} ${this.lastName}`;
    }
  } //Product Backlog Item 17176: Appointment book & related areas to display names as Preferred Name Surname

  @computed
  get reversedName() {
    if (this.type === ContactType.Organisation) {
      return this.name;
    } else {
      return this.nickName
        ? `${this.lastName}, (${this.firstName}) ${this.nickName}`
        : `${this.lastName}, ${this.firstName}`;
    }
  }

  @computed
  get preferredFullName() {
    if (this.type === ContactType.Organisation) {
      return this.dto.organisationName;
    } else {
      if (!this.nickName || this.nickName === this.firstName) {
        return `${this.firstName} ${this.lastName}`;
      }

      return `${this.nickName} (${this.firstName}) ${this.lastName}`;
    }
  }

  @computed
  get searchName() {
    return `${this.name} ${this.middleName ?? ""}`.toLowerCase();
  }

  @computed
  get pronoun() {
    if (
      !this.pronounSubjective &&
      !this.pronounObjective &&
      !this.pronounPossessive
    ) {
      return undefined;
    }

    return `${this.pronounSubjective ?? "-"} / ${
      this.pronounObjective ?? "-"
    } / ${this.pronounPossessive ?? "-"}`;
  }

  @computed
  get title() {
    return this.dto.fullName?.title;
  }

  @computed
  get changeLog() {
    return this.dto.changeLog;
  }

  @computed
  get titleRef() {
    return getOrUndefined(this.root.core.ref.titles.map, this.title);
  }

  @computed
  get nameWithTitle() {
    return this.titleRef?.text
      ? `${this.titleRef.text} ${this.name}`
      : this.name;
  }

  /**
   * Returns true if the contact is a Individual or Patient.
   */
  @computed
  get isPerson() {
    return (
      this.type === ContactType.Patient || this.type === ContactType.Individual
    );
  }

  @computed
  get isOrganisation() {
    return this.type === ContactType.Organisation;
  }

  @computed
  get relatedContactsLoaded() {
    return this.relationships.every(
      x => !!this.root.practice.contactsMap.get(x.relatedContactId!)
    );
  }

  @computed
  get relationships() {
    return this.dto.relationships ?? [];
  }

  @computed
  get relationshipsPerContact() {
    return groupBy(this.dto.relationships || [], x => x.relatedContactId)
      .filter(([contactId]) => this.root.practice.contactsMap.has(contactId!))
      .map(([contactId, relationships]) => ({
        contact: this.root.practice.contactsMap.get(contactId!)!,
        relationshipTypes: relationships.map(x => x.relationship)
      }));
  }

  @computed
  get employerContacts(): Contact[] {
    return filterPatientEmployers(this.relationships)
      .filter(x => this.root.practice.contactsMap.has(x.relatedContactId!))
      .map(x => this.root.practice.contactsMap.get(x.relatedContactId!)!);
  }

  /**
   * Load all related contacts.
   * For now, it is ok to load every single related contact as there should only be a few.
   * This approach (constrained by the current API) won't scale if relationship number can be very
   * large (i.e. an employer->employees relationship shouldn't be part of the returned relationships of a organisation contact).
   */
  @sharePendingPromise()
  async loadRelatedContacts(): Promise<void> {
    if (this.relatedContactsLoaded) {
      return;
    }

    const contactIds = unique(this.relationships.map(x => x.relatedContactId!));
    if (contactIds.length) {
      await this.root.practice.getContactsById(contactIds);
    }
  }

  @computed
  get initials() {
    switch (this.type) {
      case ContactType.Patient:
      case ContactType.Individual: {
        return computePersonInitials(this.firstName, this.lastName);
      }
      default: {
        return `${this.name?.[0] ?? ""}`.toUpperCase();
      }
    }
  }

  @computed
  get preferredInitials() {
    if (!this.nickName || this.nickName === this.firstName) {
      return computePersonInitials(this.firstName, this.lastName);
    }

    return computePersonInitials(this.nickName, this.lastName);
  }

  @computed
  get sex() {
    return isPatient(this.dto) ? this.dto.sex : undefined;
  }

  @computed
  get sexExpandedForm() {
    return this.root.practice.ref.sexes.keyLabelValues.find(
      keyValue => keyValue.value === this.sex
    )?.label;
  }

  @computed
  get pronounObjective() {
    return this.dto.pronounObjective;
  }

  @computed
  get pronounPossessive() {
    return this.dto.pronounPossessive;
  }

  @computed
  get pronounSubjective() {
    return this.dto.pronounSubjective;
  }

  @computed
  get gender() {
    return this.dto.gender;
  }

  @computed
  get isGenderRelevant() {
    return (
      isPatient(this.dto) &&
      ((this.sex &&
        this.gender &&
        this.sex.toString() !== this.gender.toString()) ||
        (this.gender && !this.sex))
    );
  }

  @computed
  get birthDate() {
    return DateTime.fromISO(this.dto.birthday);
  }

  @computed
  get dateOfDeath() {
    return isPatient(this.dto)
      ? DateTime.fromISO(this.dto.dateOfDeath)
      : undefined;
  }

  @computed
  get addresses() {
    return this.dto.addresses ?? [];
  }

  @computed
  get postalAddress() {
    return findAddressValue(this.addresses, AddressType.Postal);
  }

  @computed
  get physicalAddress() {
    return findAddressValue(this.addresses, AddressType.Physical);
  }

  @computed
  get bothAddress() {
    return findAddressValue(this.addresses, AddressType.Both);
  }
  @computed
  get defaultAddress() {
    return this.postalAddress || this.physicalAddress || this.bothAddress;
  }

  @computed
  get ethnicities() {
    if (isPatient(this.dto)) {
      const patientDto: PatientDto = this.dto;
      return (
        this.root.practice.ref.ethnicities.keyNameValues
          .filter(x => patientDto.ethnicities?.includes(x.key))
          .map(x => x.key) ?? []
      );
    }
    return [];
  }

  @computed
  get status() {
    return this.dto.contactStatus;
  }

  @computed
  get categories() {
    return this.dto.categories || [];
  }

  @computed
  get nhi() {
    return isPatient(this.dto) && this.dto.patientEntitlements
      ? entitlementToNHI(this.dto.patientEntitlements!)
      : undefined;
  }

  @computed
  get dva() {
    return isPatient(this.dto) && this.dto.patientEntitlements
      ? entitlementToDVA(this.dto.patientEntitlements!)
      : undefined;
  }

  @computed
  get csc() {
    return isPatient(this.dto) && this.dto.patientEntitlements
      ? entitlementToCSC(this.dto.patientEntitlements!)
      : undefined;
  }

  @computed
  get cscExpiryDate() {
    return this.csc ? DateTime.fromISO(this.csc.expiry) : undefined;
  }

  @computed
  get cscIsExpired() {
    return this.cscExpiryDate ? isExpired(this.cscExpiryDate) : false;
  }

  @computed
  get cscWillExpire() {
    return this.cscExpiryDate ? willExpire(this.cscExpiryDate) : false;
  }

  @computed
  get medicare() {
    return isPatient(this.dto) && this.dto.patientEntitlements
      ? entitlementToMedicare(this.dto.patientEntitlements!)
      : undefined;
  }

  @computed
  get medicareExpiryDate() {
    if (
      this.medicare &&
      (this.medicare.expiryMonth || this.medicare.expiryYear)
    ) {
      const month = this.medicare.expiryMonth ? this.medicare.expiryMonth : 0;

      const year = this.medicare.expiryYear
        ? this.medicare.expiryYear
        : DateTime.now().year;
      return DateTime.fromObject({ year, month, day: 1 });
    }
    return undefined;
  }

  @computed
  get medicareIsExpired() {
    return this.medicareExpiryDate ? isExpired(this.medicareExpiryDate) : false;
  }

  @computed
  get medicareWillExpire() {
    return this.medicareExpiryDate
      ? willExpire(this.medicareExpiryDate)
      : false;
  }

  @computed
  get healthInsurance() {
    return isPatient(this.dto) && this.dto.patientEntitlements
      ? entitlementToHealthFund(this.dto.patientEntitlements!)
      : undefined;
  }

  @computed
  get healthInsuranceExpiryDate() {
    return this.healthInsurance && this.healthInsurance.expiry
      ? DateTime.fromISO(this.healthInsurance.expiry)
      : undefined;
  }

  @computed
  get healthInsuranceIsExpired() {
    return this.healthInsuranceExpiryDate
      ? isExpired(this.healthInsuranceExpiryDate)
      : false;
  }

  @computed
  get healthInsuranceWillExpire() {
    return this.healthInsuranceExpiryDate
      ? willExpire(this.healthInsuranceExpiryDate)
      : false;
  }

  @computed
  get relationshipStatus() {
    return isPatient(this.dto) ? this.dto.relationshipStatus : undefined;
  }

  @computed
  get interpreterLanguage() {
    return isPatient(this.dto) ? this.dto.interpreterLanguage : undefined;
  }

  @computed
  get interpreterLanguageValue(): string | undefined {
    return this.interpreterLanguage
      ? this.root.practice.ref.languages.get(this.interpreterLanguage)?.text
      : undefined;
  }

  @computed
  get occupation() {
    return isPatient(this.dto) ? this.dto.occupation : undefined;
  }

  @computed
  get internalProviders() {
    return isPatient(this.dto) ? this.dto.internalProvider ?? [] : [];
  }

  @computed
  get externalProviders() {
    return isPatient(this.dto) ? this.dto.externalProvider ?? [] : [];
  }

  @computed
  get profilePictureUrl() {
    return this.dto.profilePictureUrl;
  }

  @computed
  get patientEntitlements() {
    return isPatient(this.dto) ? this.dto.patientEntitlements ?? [] : [];
  }

  @computed
  get isPatient() {
    return isPatient(this.dto);
  }

  @computed
  get organisationRoles() {
    return (this.dto as OrganisationContactDto).organisationRoles ?? [];
  }

  @computed
  get organisationRolesEmployerEmails() {
    const privateInsurers = this.organisationRoles.filter(isPrivateInsurer);
    const employers = this.organisationRoles.filter(isEmployerDto);
    return [...privateInsurers, ...employers]
      .map(x => [x.invoicingEmail, x.claimsAdminEmail].filter(isDefined))
      .flat();
  }

  @computed
  get privateInsurer() {
    return isOrganisation(this.dto) && this.dto.organisationRoles
      ? orgRolesToPrivateInsurer(this.dto.organisationRoles, false)
      : undefined;
  }

  @computed
  get employer() {
    return isOrganisation(this.dto) && this.dto.organisationRoles
      ? orgRolesToEmployer(this.dto.organisationRoles)
      : undefined;
  }

  @computed
  get healthProvider() {
    return isOrganisation(this.dto) && this.dto.organisationRoles
      ? orgRolesToHealthProvider(this.dto.organisationRoles)
      : undefined;
  }

  @computed
  get claimsAdminEmail() {
    return (
      this.privateInsurer?.claimsAdminEmail || this.employer?.claimsAdminEmail
    );
  }

  @computed
  get invoicingEmail() {
    return this.privateInsurer?.invoicingEmail || this.employer?.invoicingEmail;
  }

  @computed
  get accountHolders() {
    return this.relationships
      .filter(x => x.relationship === RelationshipType.AccountHolder)
      .sort(x => (x.metadata?.isPrimary ? -1 : 1));
  }

  @computed
  get primaryAccountHolder() {
    return this.accountHolders.find(x => x.metadata?.isPrimary);
  }

  private get orgBillingEmail(): string | undefined {
    if (this.employer?.accreditedEmployer) {
      if (this.employer.invoicingEmail) {
        return this.employer.invoicingEmail;
      }

      if (this.employer.claimsAdminEmail) {
        return this.employer.claimsAdminEmail;
      }
    }

    if (this.privateInsurer?.accPrivateInsurer) {
      if (this.privateInsurer.invoicingEmail) {
        return this.privateInsurer.invoicingEmail;
      }

      if (this.privateInsurer.claimsAdminEmail) {
        return this.privateInsurer.claimsAdminEmail;
      }
    }

    return this.email;
  }

  @computed get contactPreferences() {
    return this.root.comms.contactPreferencesMap.get(this.id);
  }

  get billingOptedOut(): boolean {
    return !!this.contactPreferences?.invoiceCommunicationPreferences
      ?.contactHasOptedOut;
  }

  @computed
  get billingEmail(): string | undefined {
    if (this.billingOptedOut) {
      return undefined;
    }

    if (this.isOrganisation) {
      return this.orgBillingEmail;
    }

    if (this.contactPreferences?.invoiceCommunicationPreferences) {
      const { preferredCommChannelTypeCode, preferredCommAddressValue } =
        this.contactPreferences.invoiceCommunicationPreferences;

      if (
        preferredCommChannelTypeCode === OutboundCommChannel.Email &&
        preferredCommAddressValue
      ) {
        return preferredCommAddressValue;
      }
    }

    return this.email;
  }
}

export const isOrganisation = (
  organisation: ContactDto
): organisation is OrganisationContactDto => {
  return organisation.type === ContactType.Organisation;
};

export const isEmployerDto = (
  organisationRole: OrganisationRoleDto
): organisationRole is EmployerDto =>
  organisationRole.organisationRoleTypeCode ===
    OrganisationRoleType.NzEmployer ||
  organisationRole.organisationRoleTypeCode === OrganisationRoleType.AuEmployer;

export const isPrivateInsurer = (
  organisationRole: OrganisationRoleDto
): organisationRole is PrivateInsurerDto =>
  organisationRole.organisationRoleTypeCode ===
    OrganisationRoleType.NzPrivateInsurer ||
  organisationRole.organisationRoleTypeCode ===
    OrganisationRoleType.AuPrivateInsurer;

export const orgRolesToPrivateInsurer = (
  orgRoles: OrganisationRoleDto[],
  accOnly: boolean
): PrivateInsurerDto | undefined => {
  const nzPrivateInsurerDto = orgRoles.find(
    x => x.organisationRoleTypeCode === OrganisationRoleType.NzPrivateInsurer
  ) as PrivateInsurerDto;

  const auPrivateInsurerDto = orgRoles.find(
    x => x.organisationRoleTypeCode === OrganisationRoleType.AuPrivateInsurer
  ) as PrivateInsurerDto;

  if (
    !!nzPrivateInsurerDto &&
    (!accOnly || nzPrivateInsurerDto.accPrivateInsurer)
  ) {
    return nzPrivateInsurerDto;
  }
  if (!!auPrivateInsurerDto) {
    return auPrivateInsurerDto;
  }
  return undefined;
};

export const orgRolesToPublicInsurer = (
  orgRoles: OrganisationRoleDto[]
): PublicInsurerDto | undefined => {
  const publicInsurer = orgRoles.find(
    x =>
      x.organisationRoleTypeCode === OrganisationRoleType.NzPublicInsurer ||
      x.organisationRoleTypeCode === OrganisationRoleType.AuPublicInsurer
  );
  if (!publicInsurer) {
    return undefined;
  } else {
    return publicInsurer as PublicInsurerDto;
  }
};

export const orgRolesToEmployer = (
  orgRoles: OrganisationRoleDto[]
): EmployerDto | undefined => {
  const employerDto = orgRoles.find(
    x =>
      x.organisationRoleTypeCode === OrganisationRoleType.NzEmployer ||
      x.organisationRoleTypeCode === OrganisationRoleType.AuEmployer
  );
  if (!employerDto) {
    return undefined;
  } else {
    return employerDto as EmployerDto;
  }
};

export const orgRolesToHealthProvider = (
  orgRoles: OrganisationRoleDto[]
): EmployerDto | undefined => {
  const healthProviderDto = orgRoles.find(
    x =>
      x.organisationRoleTypeCode === OrganisationRoleType.NzHealthProvider ||
      x.organisationRoleTypeCode === OrganisationRoleType.AuHealthProvider
  );
  if (!healthProviderDto) {
    return undefined;
  } else {
    return healthProviderDto as HealthProviderDto;
  }
};

export const toOrganisationRoles = (
  params: {
    privateInsurerDto?: PrivateInsurerDto | undefined;
    publicInsurerDto?: PublicInsurerDto | undefined;
    employerDto?: EmployerDto | undefined;
    healthProviderDto?: HealthProviderDto | undefined;
  },
  country: string
): OrganisationRoleDto[] => {
  const {
    privateInsurerDto,
    publicInsurerDto,
    employerDto,
    healthProviderDto
  } = params;

  const orgRoles: OrganisationRoleDto[] = [];
  if (privateInsurerDto) {
    orgRoles.push({
      ...privateInsurerDto,
      organisationRoleTypeCode:
        country === Country.NewZealand
          ? OrganisationRoleType.NzPrivateInsurer
          : OrganisationRoleType.AuPrivateInsurer
    });
  }
  if (employerDto) {
    orgRoles.push({
      ...employerDto,
      organisationRoleTypeCode:
        country === Country.NewZealand
          ? OrganisationRoleType.NzEmployer
          : OrganisationRoleType.AuEmployer
    });
  }
  if (healthProviderDto) {
    orgRoles.push({
      ...healthProviderDto,
      organisationRoleTypeCode:
        country === Country.NewZealand
          ? OrganisationRoleType.NzHealthProvider
          : OrganisationRoleType.AuHealthProvider
    });
  }
  if (publicInsurerDto) {
    orgRoles.push({
      organisationRoleTypeCode:
        country === Country.NewZealand
          ? OrganisationRoleType.NzPublicInsurer
          : OrganisationRoleType.AuPublicInsurer
    });
  }
  return orgRoles;
};

const isPatient = (contact: ContactDto): contact is PatientDto => {
  return contact.type === ContactType.Patient;
};

export const entitlementToDVA = (
  entitlements: EntitlementDto[]
): DvaDto | undefined => {
  const dvaEntitlement = entitlements.find(
    ent => ent.patientEntitlementTypeCode === EntitlementType.DVA
  );
  if (!dvaEntitlement) {
    return undefined;
  } else {
    return dvaEntitlement as DvaDto;
  }
};

export const entitlementToMedicare = (
  entitlements: EntitlementDto[]
): MedicareDto | undefined => {
  const medicareEntitlement = entitlements.find(
    ent => ent.patientEntitlementTypeCode === EntitlementType.Medicare
  );
  if (!medicareEntitlement) {
    return undefined;
  } else {
    return medicareEntitlement as MedicareDto;
  }
};

export const entitlementToHealthFund = (
  entitlements: EntitlementDto[]
): HealthFundDto | undefined => {
  const healthFundEntitlement = entitlements.find(
    ent => ent.patientEntitlementTypeCode === EntitlementType.HealthFund
  );
  if (!healthFundEntitlement) {
    return undefined;
  } else {
    return healthFundEntitlement as HealthFundDto;
  }
};

export const entitlementToCSC = (
  entitlements: EntitlementDto[]
): CscDto | undefined => {
  const cscEntitlement = entitlements.find(
    ent => ent.patientEntitlementTypeCode === EntitlementType.CSC
  );
  if (!cscEntitlement) {
    return undefined;
  } else {
    return cscEntitlement as CscDto;
  }
};

export const entitlementToNHI = (
  entitlements: EntitlementDto[]
): NhiDto | undefined => {
  const nhiEntitlement = entitlements.find(
    ent => ent.patientEntitlementTypeCode === EntitlementType.NHI
  );
  if (!nhiEntitlement) {
    return undefined;
  } else {
    return nhiEntitlement as NhiDto;
  }
};

export const toEntitlements = (params: {
  med: MedicareDto | undefined;
  hf: HealthFundDto | undefined;
  dva: DvaDto | undefined;
  nhi: NhiDto | undefined;
  csc: CscDto | undefined;
}): EntitlementDto[] => {
  const { med, hf, dva, nhi, csc } = params;
  const ent: EntitlementDto[] = [];
  if (med) {
    ent.push({
      patientEntitlementTypeCode: EntitlementType.Medicare,
      ...med
    });
  }
  if (hf) {
    ent.push({
      patientEntitlementTypeCode: EntitlementType.HealthFund,
      ...hf
    });
  }
  if (dva) {
    ent.push({
      patientEntitlementTypeCode: EntitlementType.DVA,
      ...dva
    });
  }
  if (nhi) {
    ent.push({
      patientEntitlementTypeCode: EntitlementType.NHI,
      ...nhi
    });
  }
  if (csc) {
    ent.push({
      patientEntitlementTypeCode: EntitlementType.CSC,
      ...csc
    });
  }
  return ent;
};

export const contactFirstNameCompareFn = (a?: Contact, b?: Contact): number =>
  (a?.firstName ?? "") < (b?.firstName ?? "") ? -1 : 0;
