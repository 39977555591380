import { DateTime } from "@bps/utils";
import {
  AddressDto,
  CommunicationDto,
  ContactBaseDto,
  RelationshipDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { employerRelationships } from "@shared-types/practice/employer.relationship.constant.ts";

export function findCommValue(
  array: CommunicationDto[] | undefined,
  type: string
) {
  const comm = array && array.find(x => x.type === type);
  return comm && comm.value;
}

export const sortEmployers = (
  employer1: RelationshipDto,
  employer2: RelationshipDto
): number => {
  const metadata1 = employer1.metadata;
  const metadata2 = employer2.metadata;

  const currentJob1 = metadata1 && metadata1.currentJob ? 1 : 0;
  const currentJob2 = metadata2 && metadata2.currentJob ? 1 : 0;
  const primaryJob1 = metadata1 && metadata1.primaryJob ? 1 : 0;
  const primaryJob2 = metadata2 && metadata2.primaryJob ? 1 : 0;

  return primaryJob2 - primaryJob1 || currentJob2 - currentJob1;
};
export const filterPatientEmployers = (relationships: RelationshipDto[]) =>
  relationships
    .filter(x => employerRelationships.includes(x.relationship))
    .sort(sortEmployers);
export const isExpired = (expiryDate: DateTime) => {
  const today = DateTime.now();
  const todayMinusOneYear = DateTime.now().minus({ years: 1 });

  return expiryDate <= today && expiryDate >= todayMinusOneYear;
};
export const willExpire = (expiryDate: DateTime) => {
  const today = DateTime.now();
  const todayPlusOneMonth = DateTime.now().plus({ months: 1 });

  return expiryDate >= today && expiryDate <= todayPlusOneMonth;
};
export const capitaliseFullName = (
  fullName: ContactBaseDto["fullName"]
): ContactBaseDto["fullName"] => {
  return (
    fullName && {
      ...fullName,
      firstName:
        fullName.firstName &&
        capitalizeSentence(fullName.firstName, { allWords: true }),
      lastName:
        fullName.lastName &&
        capitalizeSentence(fullName.lastName, { allWords: true }),
      middleName:
        fullName.middleName &&
        capitalizeSentence(fullName.middleName, { allWords: true }),
      nickName:
        fullName.nickName &&
        capitalizeSentence(fullName.nickName, { allWords: true })
    }
  );
};
export const capitaliseAddress = <
  T extends Pick<
    AddressDto,
    "city" | "state" | "street1" | "street2" | "suburb"
  >
>(
  address: T
): T => {
  return {
    ...address,
    city: address.city && capitalizeSentence(address.city, { allWords: true }),
    state:
      address.state && capitalizeSentence(address.state, { allWords: true }),
    street1:
      address.street1 &&
      capitalizeSentence(address.street1, { allWords: true }),
    street2:
      address.street2 &&
      capitalizeSentence(address.street2, { allWords: true }),
    suburb:
      address.suburb && capitalizeSentence(address.suburb, { allWords: true })
  };
};
export const capitaliseAddresses = (
  addresses: ContactBaseDto["addresses"]
): ContactBaseDto["addresses"] => {
  return addresses?.map(capitaliseAddress);
};
