import { action, observable } from "mobx";

import { InboxDocumentsStore } from "./InboxDocumentsStore.ts";

export class InboxDocumentsUi {
  constructor(private store: InboxDocumentsStore) {}

  @observable
  documentsCount: number = 0;

  @observable
  documentRefreshKey: string = "";

  @action
  setDocumentRefreshKey = (key: string) => {
    this.documentRefreshKey = key;
  };

  @observable
  userTaskRefreshKey: string = "";

  @action
  setUserTaskRefreshKey = ({ eTag, id }: { eTag?: string; id?: string }) => {
    this.userTaskRefreshKey = eTag || `${id}updated`;
  };
}
