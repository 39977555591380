import { computed } from "mobx";

import { DateTime } from "@bps/utils";
import { InboxDocumentDto } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { User } from "@stores/core/models/User.ts";

export class InboxDocument extends Model<InboxDocumentDto> {
  _previewUri: string | undefined = undefined;
  _downloadLinkUri: string | undefined = undefined;

  constructor(
    private store: IRootStore,
    dto: InboxDocumentDto
  ) {
    super(dto);
  }

  @computed
  get fromContactId(): string {
    return this.dto.fromContactId;
  }

  @computed
  get patientId(): string {
    return this.dto.patientId;
  }

  @computed
  get senderUserName(): string {
    return this.dto.changeLog?.createdBy!;
  }

  @computed
  get assignedToUserId(): string {
    return this.dto.assignedToUserId;
  }

  @computed
  get checkedBy(): string | undefined {
    return this.dto.checkedBy;
  }

  @computed
  get checkedDate(): string | undefined {
    return this.dto.checkedDateTime;
  }

  @computed
  get name(): string {
    return this.dto.name;
  }

  @computed
  get secGroupId(): string | undefined {
    return this.dto.secGroupId;
  }

  @computed
  get documentDate(): Date | undefined {
    return DateTime.jsDateFromISO(this.dto.documentDate?.split("T")[0]);
  }

  @computed
  get receivedDate(): Date | undefined {
    return DateTime.jsDateFromISO(this.dto.receivedDate?.split("T")[0]);
  }

  @computed
  get docExtension(): string | undefined {
    return this.dto.extension;
  }

  @computed
  get dateUploaded(): string | undefined {
    return this.dto.changeLog?.createdDate;
  }

  @computed
  get containerUri(): string | undefined {
    return this.dto.containerUri;
  }

  @computed
  get containerToken(): string | undefined {
    return this.dto.containerToken;
  }

  @computed
  get documentDetailId(): string {
    return this.dto.documentDetailId;
  }

  @computed
  get blobSasUri(): string | undefined {
    return this.dto.blobSasUri;
  }

  @computed
  get fileUri(): string | undefined {
    return this.dto.blobFileUri;
  }

  @computed
  get user() {
    return this.store.core.userMap.get(this.assignedToUserId);
  }

  @computed
  get createdBy() {
    const users = Array.from(this.store.core.userMap.values());
    return users.find(x => x.username === this.senderUserName);
  }

  @computed
  get contact() {
    return this.store.practice.contactsMap.get(this.patientId);
  }

  @computed
  get changeLog() {
    return this.dto.changeLog;
  }

  @computed
  get extraInfo(): string | undefined {
    return this.dto.extraInfo;
  }

  @computed
  get correspondenceType() {
    return this.dto.correspondenceType;
  }

  get previewUri() {
    return this._previewUri;
  }

  set previewUri(string) {
    this._previewUri = string;
  }

  @computed
  get patientFirstName() {
    return this.dto.patientFirstName;
  }

  @computed
  get patientLastName() {
    return this.dto.patientLastName;
  }

  @computed
  get headerEntityId() {
    return this.dto.headerEntityId;
  }

  @computed
  get headerDocumentDetailId() {
    return this.dto.headerDocumentDetailId;
  }

  @computed
  get showOnTimeline(): boolean | undefined {
    return this.dto.showOnTimeline;
  }

  get downloadLinkUri() {
    return this._downloadLinkUri;
  }

  set downloadLinkUri(string) {
    this._downloadLinkUri = string;
  }

  loadRelatedUser = () => {
    if (!this.assignedToUserId) return;
    return this.store.core.getUser(this.assignedToUserId);
  };

  loadCreatedByUser = () => {
    if (!this.senderUserName || this.senderUserName === User.System) return;
    return this.store.core.getUserByUserName(this.senderUserName);
  };

  loadRelatedContact = () => {
    if (!this.patientId) return;
    return this.store.practice.getContact(this.patientId);
  };
}
