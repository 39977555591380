import { action, computed, observable } from "mobx";

import {
  ClinicalDataType,
  DockableClinicalTab,
  PatientTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";

import { ClinicalTabs } from "./models/clinical-tab/ClinicalTabs.ts";

export class ClinicalUi {
  constructor(
    private clinical: ClinicalStore,
    private core: CoreStore
  ) {}

  @observable closingClinicalRecordId: string | undefined;
  @observable isConfirmationDialogVisible = false;
  @observable isConditionConsultsExportDialogVisible: boolean = false;
  @observable isUnlinkConditionConfirmationDialogVisible: boolean = false;
  @observable conditionConsultsDocumentId: string | undefined = undefined;
  public clinicalTabs: ClinicalTabs;
  openedRecordIds = observable.array<PatientTab>();

  get tabs(): ClinicalTabs {
    if (!this.clinicalTabs)
      this.clinicalTabs = new ClinicalTabs(this.clinical.root);
    return this.clinicalTabs;
  }

  @action
  closePatientRecordContentForm(
    recordId: string,
    type: DockableClinicalTab,
    contextId?: string
  ) {
    const tab = this.tabs.currentPatientRecordTab?.allTabs.find(
      x => x.type === type && x.contextId === contextId
    );

    if (tab) {
      this.tabs.currentPatientRecordTab?.removeTab(
        tab.id,
        this.tabs.currentPatientRecordTab?.activeTab?.id === tab.id
      );
    } else {
      this.tabs.currentPatientRecordTab &&
        this.tabs.currentPatientRecordTab.hideActive();
    }
  }

  @action
  setPatientClinicalContent(data: {
    type: DockableClinicalTab;
    encounterId?: string;
    title?: string;
    contextId?: string;
    toolTipText?: string;
  }) {
    if (!this.clinical.activeRecord || !this.tabs.currentPatientRecordTab)
      return;

    // a case when we switch bte opened tabs in docker
    if (
      this.tabs.currentPatientRecordTab.hasTabTypeOpen(
        data.type as ClinicalDataType,
        data.encounterId,
        data.contextId
      )
    ) {
      this.tabs.currentPatientRecordTab!.setActive(
        data.type as ClinicalDataType,
        data.encounterId,
        data.contextId
      );
      return;
    }

    // if no we create a new tab and show one
    const tab = this.tabs.createClinicalTabItem(data);
    this.tabs.currentPatientRecordTab.show(tab);
  }

  @action
  changeRecordPosition(recordId: string, encounterId?: string) {
    const record = this.openedRecordIds.find(
      x => x.patientId === recordId && x.encounterId === encounterId
    );

    if (record) {
      this.openedRecordIds.replace(
        this.openedRecordIds.filter(
          x => x.patientId !== recordId && x.encounterId === encounterId
        )
      );

      this.clinical.activeRecord = {
        patientId: record.patientId,
        encounterId: record.encounterId
      };

      this.openedRecordIds.splice(0, 0, record);
    }
  }

  @action resetClosingClinicalRecordId = () => {
    this.closingClinicalRecordId = undefined;
  };

  @action closeDiscardConfirmationDialog = () => {
    this.isConfirmationDialogVisible = false;
  };

  @action setConditionConsultsExportDialogVisible = (value: boolean) => {
    this.isConditionConsultsExportDialogVisible = value;
  };

  @action setIsUnlinkConditionConfirmationDialogVisible = (value: boolean) => {
    this.isUnlinkConditionConfirmationDialogVisible = value;
  };

  @action setConditionConsultsDocumentId = (value: string | undefined) => {
    this.conditionConsultsDocumentId = value;
  };

  @computed
  get clinicalTaskActionDisabled() {
    return (
      this.core.hasPermissions(Permission.LicencingAllowed) &&
      !this.core.hasPermissions(Permission.CreateClinicalTasksAllowed)
    );
  }

  @computed
  get clinicalActivityActionDisabled() {
    return (
      (this.core.hasPermissions(Permission.LicencingAllowed) &&
        !this.core.hasPermissions(Permission.CreateClinActivityAllowed)) ||
      !this.core.hasPermissions(Permission.ClinActivityWrite)
    );
  }
}
