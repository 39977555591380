import { action, observable } from "mobx";
import { stringify } from "query-string";

import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { ContactErrorHandler } from "@shared-types/practice/contact-error-handler.type.ts";
import { DemographicInitialValues } from "@shared-types/practice/demographic-form-values.types.ts";

import { Contact } from "./models/Contact.ts";
import type { PracticeStore } from "./PracticeStore.ts";

export class PracticeUi {
  constructor(private store: PracticeStore) {}

  @observable
  public recentlyDeletedPatientNoticeId?: string;

  @observable recentlyUpdatedProviderEtag?: string;

  @observable
  public recentlyAddedContactId?: string;

  @observable recentlyUpdatedOrgUnitEtag?: string;

  @observable
  public demographicInitialValues: DemographicInitialValues | undefined;

  @observable
  public selectedEditCard: string | undefined;

  @observable
  public currentContactId: string | undefined;

  @observable
  public newContactType: ContactType | undefined;

  @observable lastServiceETag?: string;

  public contactErrorSubscribers = new Set<ContactErrorHandler>();

  @observable
  isPatientAppointmentsSearchOnFocus: boolean = false;

  @observable showPatientMergeModal: boolean = false;

  @action
  setShowPatientMergeModal = (value: boolean) => {
    this.showPatientMergeModal = value;
  };

  @observable masterPatient?: Contact;

  @action
  setMasterPatient = (value?: Contact) => {
    this.masterPatient = value;
  };

  @observable showPatMergeConfirmation: boolean = false;

  @action
  setShowPatMergeConfirmation = (value: boolean) => {
    this.showPatMergeConfirmation = value;
  };

  @action
  setPatientAppointmentsSearchOnFocus = (value: boolean) => {
    this.isPatientAppointmentsSearchOnFocus = value;
  };

  @action
  public showEditContact = (cardId: string, id: string) => {
    this.selectedEditCard = `${cardId}-edit`;
    this.currentContactId = id;
  };

  @action
  public hideModal = () => {
    this.currentContactId = undefined;
    this.newContactType = undefined;
    this.selectedEditCard = undefined;
    this.demographicInitialValues = undefined;
  };

  @action
  public setSelectedEditCard = (cardId: string | undefined) => {
    this.selectedEditCard = cardId;
  };

  @action
  public showAddContact = (
    type: ContactType,
    initialValues?: DemographicInitialValues
  ) => {
    this.newContactType = type;
    this.demographicInitialValues = initialValues;
  };

  @action
  showContactDetails = (id: string, section?: string) => {
    this.store.root.routing.pushWithFromQuery({
      pathname: routes.contacts.contact.path({ id }),
      search: stringify({ section })
    });
  };

  public onContactError(handler: ContactErrorHandler) {
    this.contactErrorSubscribers.add(handler);
  }
}
