import { action, computed, observable, runInAction } from "mobx";

import { last, unique } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { IHubGateway } from "@libs/api/hub/HubGateway.ts";
import { notificationMessages } from "@libs/constants/notification-messages.constants.ts";
import {
  deepEqualResolver,
  sharePendingPromise
} from "@libs/decorators/sharePendingPromise.ts";
import {
  DocumentContentDto,
  DocumentCreateOptions,
  DocumentDeleteArgsDto,
  DocumentDto,
  DocumentEnum,
  DocumentMoveArgsDto,
  DocumentTabStatus,
  DocumentUrlDto,
  DocumentWriterTab,
  GetDocumentUrlArgs,
  PatientTab,
  StoreType
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { IClinicalGateway } from "@libs/gateways/clinical/ClinicalGateway.interface.ts";
import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import type { Store } from "@stores/types/store.type.ts";
import { mergeModel } from "@stores/utils/store.utils.ts";

import { CorrespondenceUi } from "./CorrespondenceUi.ts";
import { ClinicalDocument } from "./models/ClinicalDocument.ts";
import { CorrespondenceRef } from "./ref/CorrespondenceRef.ts";
import {
  clinicalRecordMapKey,
  getClinicalDocumentStoreString
} from "./utils/clinical.utils.ts";

enum InvestigationType {
  Request = "request",
  Report = "report"
}

const EMPTY_SECGROUPID = "EMPTY";

export interface DocumentsFilter {
  patientId: string;
  direction?: string;
  types?: string[];
  statuses?: string[];
  searchText?: string;
  id?: string;
  confidential?: boolean;
  investigationType?: InvestigationType;
}

export class CorrespondenceStore
  implements Store<CorrespondenceStore, CorrespondenceRef>
{
  constructor(
    private gateway: IClinicalGateway,
    public hub: IHubGateway
  ) {
    this.ref = new CorrespondenceRef(this.gateway);
  }
  afterAttachRoot() {
    this.hub.onEntityEvent(Entity.ClinicalDocument, this.onDocumentEvent);
  }

  root: IRootStore;
  ref: CorrespondenceRef;
  ui = new CorrespondenceUi(this);

  private get core() {
    return this.root.core;
  }

  private get practice() {
    return this.root.practice;
  }

  private get clinical() {
    return this.root.clinical;
  }

  private get notification() {
    return this.root.notification;
  }

  private get routing() {
    return this.root.routing;
  }

  @observable
  openedDocumentTabs = observable(new Array<DocumentWriterTab>());
  correspondenceMap = observable.map<string, ClinicalDocument>();
  investigationsMap = observable.map<string, ClinicalDocument>();

  @computed
  get investigationMapValues() {
    return Array.from(this.investigationsMap.values());
  }

  @computed
  get correspondenceMapValues() {
    return Array.from(this.correspondenceMap.values());
  }

  @observable
  correspondenceListRefreshKey: string;

  @observable investigationListRefreshKey: string;

  @computed get editInboxRefreshKey(): string {
    return `${this.correspondenceListRefreshKey}-${this.investigationListRefreshKey}`;
  }

  private onDocumentEvent = async (event: EntityEventData<string>) => {
    try {
      const keys = event.key.split(":");
      if (keys.length !== 5) return;

      const prefix = keys[0];
      const documentId = keys[2];
      const patientId = keys[3];
      const encounterId = keys[4];

      const clinicalRecord = this.clinical.multipleEncountersRecordsMap.get(
        clinicalRecordMapKey(patientId, encounterId)
      );

      if (
        event.action === EventAction.Create ||
        event.action === EventAction.Update
      ) {
        const dto = await this.gateway.getDocumentByDocumentId(
          patientId,
          documentId
        );

        if (dto) {
          const store = dto.metadata?.find(m => m.key === DocumentEnum.Store)
            ?.value;

          if (store === StoreType.Investigations) {
            const investigation = await this.getInvestigationByDocumentId(
              patientId,
              documentId,
              { ignoreCache: true }
            );
            runInAction(() => {
              this.investigationListRefreshKey =
                investigation.id + investigation.eTag;
            });
          } else {
            if (
              event.action === EventAction.Create &&
              this.ui.isWaitingDocumentEvent &&
              dto.patientId === this.ui.readyToSendEmailPatientId
            ) {
              const name: string = dto.metadata?.find(
                m => m.key === DocumentEnum.Name
              )?.value;
              if (name.includes(DocumentEnum.Acc45Summary)) {
                runInAction(() => {
                  this.ui.readyToSendEmailDocumentId = dto.id;
                });
              }
            }

            const correspondence = await this.getCorrespondenceByDocumentId(
              patientId,
              documentId,
              { ignoreCache: true }
            );
            if (correspondence) {
              runInAction(() => {
                this.correspondenceListRefreshKey =
                  correspondence.id + correspondence.eTag;
              });
            }
          }

          if (clinicalRecord) {
            // Document Id updating sends another update, we don't need to tell the user it's been uploaded twice.

            const storeString = getClinicalDocumentStoreString(store);

            if (
              prefix !== "documentIdUpdate" &&
              store !== StoreType.Prescriptions
            ) {
              this.notification.success(
                `Document has been uploaded to ${storeString}`
              );
            }

            // When a document is uploaded, we update its ID to match what is stored in the system.
            // We need to reload the clinical data when a document is uploaded to stay concurrent.
            clinicalRecord.loadClinicalData();
            clinicalRecord.loadStructuredNotes(
              encounterId,
              this.root.userExperience.settings.ClinicalNotesFormat
            );
          }
        }
      }
      if (event.action === EventAction.Delete && clinicalRecord) {
        //Need to load the clinicalNotes
        clinicalRecord.loadStructuredNotes(
          encounterId,
          this.root.userExperience.settings.ClinicalNotesFormat
        );

        const document =
          this.correspondenceMap.get(event.id) ||
          this.investigationsMap.get(event.id);

        if (document) {
          const store = document.metadata?.find(
            m => m.key === DocumentEnum.Store
          )?.value;
          if (store === StoreType.Investigations) {
            runInAction(() => {
              this.investigationListRefreshKey = document.id;
              this.investigationsMap.delete(document.id);
            });
          } else {
            runInAction(() => {
              this.correspondenceListRefreshKey = document.id + document.eTag;
              this.correspondenceMap.delete(document.id);
            });
          }
        }
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  async addDocuments(encounterId: string, request: DocumentCreateOptions) {
    try {
      await this.gateway.createDocuments(encounterId, request);

      this.notification.success(notificationMessages.documentSent);
    } catch (e) {
      this.notification.error(e);
      throw e;
    }
  }

  async addDocumentsByPatientId(
    patientId: string,
    request: DocumentCreateOptions
  ) {
    try {
      await this.gateway.addDocumentsByPatientId(patientId, request);
      this.notification.success(notificationMessages.documentSent);
    } catch (e) {
      this.notification.error(e);
      throw e;
    }
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async fetchCorrespondence(
    filter: DocumentsFilter & PagingOptions
  ): Promise<QueryResult<ClinicalDocument>> {
    const correspondenceFilter = { ...filter, store: StoreType.Correspondence };
    const dtoResult = await this.gateway.getDocuments(correspondenceFilter);
    const { results, ...rest } = dtoResult;

    const fromIds = dtoResult.results
      .filter(item => item.from != null)
      .map(item => item.from!);

    const toIds = dtoResult.results
      .filter(item => item.to != null)
      .map(item => item.to!);

    const peopleIds = unique(fromIds.concat(toIds));
    await this.practice.getContactsById(peopleIds);
    await Promise.all(
      peopleIds.map(userId => this.core.getUser(userId).catch(() => null))
    );

    return observable({
      results: results.map(result => this.mergeCorrespondence(result)),
      ...rest
    });
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async fetchInvestigations(
    filter: DocumentsFilter & PagingOptions
  ): Promise<QueryResult<ClinicalDocument>> {
    const investigationFilter = { ...filter, store: StoreType.Investigations };

    const dtoResult = await this.gateway.getDocuments(investigationFilter);

    const { results, ...rest } = dtoResult;

    return {
      results: results.map(result => this.mergeCorrespondence(result)),
      ...rest
    };
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async fetchClinicalImages(
    filter: DocumentsFilter & PagingOptions
  ): Promise<QueryResult<ClinicalDocument>> {
    const clinicalImageFilter = { ...filter, store: StoreType.ClinicalImages };
    const dtoResult = await this.gateway.getDocuments(clinicalImageFilter);
    const { results, ...rest } = dtoResult;

    return {
      results: results.map(result => this.mergeCorrespondence(result)),
      ...rest
    };
  }

  @action
  mergeCorrespondence = (dto: DocumentDto) =>
    this.mergeDocument(dto, this.correspondenceMap);

  @action
  mergeInvestigation = (dto: DocumentDto) =>
    this.mergeDocument(dto, this.investigationsMap);

  @action
  mergeDocument = (dto: DocumentDto, map: Map<string, ClinicalDocument>) =>
    mergeModel({
      map,
      dto,
      getNewModel: () => new ClinicalDocument(dto, this)
    });

  async getCorrespondenceByDocumentId(
    patientId: string,
    documentId: string,
    options?: {
      ignoreCache?: boolean;
      includeContent?: boolean;
      contentType?: string | undefined;
    }
  ): Promise<ClinicalDocument | undefined> {
    if (!options?.ignoreCache) {
      const receivedDocument = this.correspondenceMap.get(documentId);

      if (!!receivedDocument) {
        if (options?.includeContent && !receivedDocument.content) {
          await receivedDocument.preloadContent(options.contentType);
        }
        return receivedDocument;
      }
    }

    try {
      const dto = await this.gateway.getDocumentByDocumentId(
        patientId,
        documentId
      );

      const model = this.mergeCorrespondence(dto);

      if (options?.includeContent) {
        await model.preloadContent(options.contentType);
      }

      return model;
    } catch (e) {
      this.notification.error(e, {
        messageOverride: "Could not find the related document."
      });
      return;
    }
  }

  async getInvestigationByDocumentId(
    patientId: string,
    documentId: string,
    options?: {
      ignoreCache?: boolean;
      includeContent?: boolean;
      contentType?: string;
    }
  ): Promise<ClinicalDocument> {
    if (!options?.ignoreCache) {
      const document = this.correspondenceMap.get(documentId);

      if (!!document) {
        if (options?.includeContent && !document.content) {
          const contentDto = await this.gateway.getDocumentContent(
            patientId,
            documentId,
            options.contentType
          );

          const dto = document.dto;
          dto.content = contentDto.content;
          return this.mergeInvestigation(dto);
        }

        return document;
      }
    }

    const dto = await this.gateway.getDocumentByDocumentId(
      patientId,
      documentId
    );

    if (options?.includeContent) {
      const contentDto = await this.gateway.getDocumentContent(
        patientId,
        documentId,
        options.contentType
      );

      dto.content = contentDto.content;
    }

    return this.mergeCorrespondence(dto);
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getDocumentUrl(args: GetDocumentUrlArgs): Promise<DocumentUrlDto> {
    return await this.gateway.getDocumentUrl(args);
  }

  @action
  async updateCorrespondence(
    encounterId: string,
    documentId: string,
    request: DocumentDto
  ) {
    const document = await this.gateway.updateDocument(
      encounterId,
      documentId,
      request
    );

    this.notification.success("Document has been updated");

    if (document.store === StoreType.Correspondence) {
      return this.mergeCorrespondence(document);
    } else {
      return this.mergeInvestigation(document);
    }
  }

  @action
  async deleteDocument(
    encounterId: string,
    documentId: string,
    args: DocumentDeleteArgsDto
  ) {
    try {
      await this.gateway.deleteDocument(encounterId, documentId, args);
      this.notification.success("Document has been deleted.");
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred deleting the document."
      });
      throw error;
    }
  }

  @action
  async moveDocument(
    encounterId: string,
    documentId: string,
    args: DocumentMoveArgsDto
  ) {
    try {
      await this.gateway.moveDocument(encounterId, documentId, args);
      this.notification.success("Document has been moved.");
    } catch (error) {
      throw error;
    }
  }

  @computed
  // new documents are set here before they are added to the map
  get activeDocumentTab(): DocumentWriterTab | undefined {
    let documentTab: DocumentWriterTab | undefined;

    const id = this.routing.match(routes.documentWriter.document)?.params.id;

    if (id) {
      const document = this.correspondenceMap.get(id);

      if (document) {
        documentTab = this.openedDocumentTabs.find(x => x.documentId === id);
      }
    }

    return documentTab;
  }

  set activeDocumentTab(documentTab: DocumentWriterTab | undefined) {
    if (documentTab) {
      let tab: DocumentWriterTab = documentTab;
      if (this.activeDocumentTab?.documentId === documentTab.documentId) {
        return;
      }

      const id = documentTab.documentId;
      const mapTab = this.openedDocumentTabs.find(
        x =>
          x.documentId === id &&
          x.patientId === documentTab.patientId &&
          x.encounterId === documentTab.encounterId
      );

      if (mapTab) {
        tab = mapTab;
      } else {
        runInAction(() => {
          this.openedDocumentTabs.push(tab);
        });

        this.clinical.saveToUserStorage(
          UserStorageKeys.OpenDocuments,
          this.openedDocumentTabs
        );
      }

      this.routing.push(
        {
          pathname: id
            ? routes.documentWriter.document.path({ id })
            : routes.dashboard.basePath.pattern
        },
        tab
      );
    }
  }

  @action
  async closeDocument(documentId: string) {
    const index = this.openedDocumentTabs.findIndex(
      d => d.documentId === documentId
    );

    const closingActiveTab = this.activeDocumentTab?.documentId === documentId;

    const map = this.openedDocumentTabs[index];
    let record: PatientTab | undefined;

    if (map) {
      this.openedDocumentTabs.remove(map);

      await this.clinical.saveToUserStorage(
        UserStorageKeys.OpenDocuments,
        this.openedDocumentTabs
      );
      record = this.clinical.ui.openedRecordIds.find(
        r => r.patientId === map.patientId && r.encounterId === map.encounterId
      );
    }

    if (!closingActiveTab) {
      return;
    }

    const nextMap = this.openedDocumentTabs.length
      ? this.openedDocumentTabs[Math.max(0, index - 1)]
      : undefined;

    // shift to next document record or to the last patient record
    if (record) {
      this.clinical.activeRecord = {
        patientId: record.patientId,
        encounterId: record.encounterId
      };
    } else if (nextMap) {
      this.activeDocumentTab = nextMap;
    } else {
      const lastTab = last(this.clinical.ui.openedRecordIds);
      this.clinical.activeRecord = {
        patientId: lastTab?.patientId,
        encounterId: lastTab?.encounterId
      };
    }
  }

  @action
  async closeAllDocuments() {
    this.openedDocumentTabs.forEach(x => {
      this.openedDocumentTabs.remove(x);
    });

    await this.clinical.saveToUserStorage(
      UserStorageKeys.OpenDocuments,
      this.openedDocumentTabs
    );
  }

  async getDocumentContent(
    patientId: string,
    documentId: string,
    contentType?: string | undefined
  ): Promise<DocumentContentDto> {
    return await this.gateway.getDocumentContent(
      patientId,
      documentId,
      contentType
    );
  }

  isNotNullSecGroupId(secGroupId?: string) {
    return !!secGroupId && secGroupId !== EMPTY_SECGROUPID;
  }

  @action
  updateDocumentTab = (documentId: string) => {
    const tab = this.openedDocumentTabs.find(x => x.documentId === documentId);

    if (tab) {
      tab.documentTabStatus = DocumentTabStatus.Edit;

      this.clinical.saveToUserStorage(
        UserStorageKeys.OpenDocuments,
        this.openedDocumentTabs
      );
    }
  };
}
