import { RefDataAccessor } from "@libs/api/ref-data/RefDataAccessor.ts";
import {
  InjuryArea,
  IntakeFlagRefDataDto,
  IntakeFrequency,
  IntakeStatus,
  IntakeType,
  IntakeUnit
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { IClinicalGateway } from "@libs/gateways/clinical/ClinicalGateway.interface.ts";
import { Ref } from "@stores/types/ref.type.ts";

export class ClinicalRef implements Ref<keyof ClinicalRef> {
  constructor(private gateway: IClinicalGateway) {}

  intakeFrequencies = new RefDataAccessor<IntakeFrequency>(() =>
    this.gateway.getIntakeFrequencies()
  );

  eyeRetinopathies = new RefDataAccessor(() =>
    this.gateway.getEyeRetinopathies()
  );

  reasonsForDiscard = new RefDataAccessor(() =>
    this.gateway.getReasonsForDiscard()
  );

  reasonsForDeleteReaction = new RefDataAccessor(() =>
    this.gateway.getReasonsForDeleteReaction()
  );

  reasonForDeleteCorrespondence = new RefDataAccessor(() =>
    this.gateway.getReasonsForDeleteCorrespondence()
  );

  reasonsForDeleteMedication = new RefDataAccessor(() =>
    this.gateway.getReasonsForDeleteMedicalHistory()
  );

  reasonsForDeleteMedicalHistory = new RefDataAccessor(() =>
    this.gateway.getReasonsForDeleteMedicalHistory()
  );

  reasonForDeleteInvestigationReport = new RefDataAccessor(() =>
    this.gateway.getReasonsForDeleteInvestigationReport()
  );

  reasonsForDelete = new RefDataAccessor(() =>
    this.gateway.getReasonsForDelete()
  );

  drinkFrequencies = new RefDataAccessor(() =>
    this.gateway.getDrinkFrequencies()
  );

  cessationAdvices = new RefDataAccessor(() =>
    this.gateway.getCessationAdvices()
  );

  eyeForeignBodies = new RefDataAccessor(() =>
    this.gateway.getEyeForeignBodies()
  );

  dosingAdministrationUnits = new RefDataAccessor(() =>
    this.gateway.getDosingAdministrationUnits()
  );

  dosingDurationPeriods = new RefDataAccessor(() =>
    this.gateway.getDosingDurationPeriods()
  );

  dosingFood = new RefDataAccessor(() => this.gateway.getDosingFood());

  dosingFrequencies = new RefDataAccessor(() =>
    this.gateway.getDosingFrequencies()
  );

  rxTypes = new RefDataAccessor(() => this.gateway.getRxTypes());

  dosingOtherInstructions = new RefDataAccessor(() =>
    this.gateway.getDosingOtherInstructions()
  );

  dosingRoutes = new RefDataAccessor(() => this.gateway.getDosingRoutes());

  encounterStatuses = new RefDataAccessor(() =>
    this.gateway.getEncounterStatuses()
  );

  intakeUnits = new RefDataAccessor<IntakeUnit>(() =>
    this.gateway.getIntakeUnits()
  );

  reactionTypes = new RefDataAccessor(() => this.gateway.getReactionTypes());

  reactionCertainties = new RefDataAccessor(() =>
    this.gateway.getReactionCertainties()
  );

  encounterTypes = new RefDataAccessor(() => this.gateway.getEncounterTypes());
  encounterLocations = new RefDataAccessor(() =>
    this.gateway.getEncounterLocations()
  );
  accommodations = new RefDataAccessor(() => this.gateway.getAccommodations());
  livesWith = new RefDataAccessor(() => this.gateway.getLivesWith());
  imagingModalities = new RefDataAccessor(() =>
    this.gateway.getImagingModalities()
  );
  imagingRegions = new RefDataAccessor(() => this.gateway.getImagingRegions());
  safetyInHome = new RefDataAccessor(() => this.gateway.getSafetyInHome());
  sexuality = new RefDataAccessor(() => this.gateway.getSexuality());
  relationshipStatuses = new RefDataAccessor(() =>
    this.gateway.getRelationshipStatuses()
  );
  relationships = new RefDataAccessor(() => this.gateway.getRelationships());

  natureOfReactions = new RefDataAccessor(() =>
    this.gateway.getNatureOfReactions()
  );

  reactionSeverities = new RefDataAccessor(() =>
    this.gateway.getReactionSeverity()
  );

  interactionTypes = new RefDataAccessor(() =>
    this.gateway.getInteractionTypes()
  );

  medicalCertainties = new RefDataAccessor(() =>
    this.gateway.getMedicalCertainties()
  );

  eyeVisualAcuities = new RefDataAccessor(() =>
    this.gateway.getEyeVisualAcuities()
  );

  intakeStatuses = new RefDataAccessor<IntakeStatus>(() =>
    this.gateway.getIntakeStatuses()
  );

  intakeTypes = new RefDataAccessor<
    IntakeType,
    IntakeFlagRefDataDto<IntakeType>
  >(() => this.gateway.getIntakeTypes());

  reactionWarningTypes = new RefDataAccessor(() =>
    this.gateway.getReactionWarningTypes()
  );

  sidesOfBody = new RefDataAccessor(() => this.gateway.getSidesOfBody());

  psfsReasons = new RefDataAccessor(() => this.gateway.getPsfsReasons());

  careTypes = new RefDataAccessor(() => this.gateway.getCareTypes());

  activities = new RefDataAccessor(() => this.gateway.getPhysicalActivities());

  levels = new RefDataAccessor(() => this.gateway.getPhysicalActivityLevels());

  psfsCloseReasons = new RefDataAccessor(() =>
    this.gateway.getPsfsCloseReasons()
  );

  occupations = new RefDataAccessor(() => this.gateway.getOccupations());
  workTypes = new RefDataAccessor(() => this.gateway.getWorkTypes());

  clinicalNoteSections = new RefDataAccessor(() =>
    this.gateway.getClinicalNoteSections()
  );

  clinicalTaskTypes = new RefDataAccessor(() =>
    this.gateway.getClinicalTaskTypes()
  );

  clinicalActivityTypes = new RefDataAccessor(() =>
    this.gateway.getClinicalActivityTypes()
  );
  clinicalActivityDescriptions = new RefDataAccessor(() =>
    this.gateway.getClinicalActivityDescriptions()
  );

  clinicalTaskPriorities = new RefDataAccessor(() =>
    this.gateway.getClinicalTaskPriorities()
  );

  priorities = new RefDataAccessor(() => this.gateway.getPriorities());

  clinicalTaskDeleteReasons = new RefDataAccessor(() =>
    this.gateway.getClinicalTaskDeleteReasons()
  );

  clinicalDataTypes = new RefDataAccessor(() =>
    this.gateway.getClinicalDataTypes()
  );

  generalHealthConditions = new RefDataAccessor(() =>
    this.gateway.getGeneralHealthConditions()
  );
  nerves = new RefDataAccessor(() => this.gateway.getNerves());
  reflexNerves = new RefDataAccessor(() => this.gateway.getReflexNerves());
  dermatomeEstimations = new RefDataAccessor(() =>
    this.gateway.getDermatomeEstimations()
  );
  dermatomePivotTests = new RefDataAccessor(() =>
    this.gateway.getDermatomePivotTests()
  );
  specialQuestions = new RefDataAccessor(() =>
    this.gateway.getSpecialQuestions()
  );

  clinicalFlags = new RefDataAccessor(() => this.gateway.getClinicalFlags());

  vertebrobasilarInsufficiencies = new RefDataAccessor(() =>
    this.gateway.getVertebrobasilarInsufficiencies()
  );
  injuryAreas = new RefDataAccessor<InjuryArea>(() =>
    this.gateway.getInjuryAreas()
  );
  motionTypes = new RefDataAccessor(() => this.gateway.getMotionTypes());
  injuryAreaMotionTypeGroups = new RefDataAccessor(() =>
    this.gateway.getInjuryAreaMotionTypeGroups()
  );
  investigations = new RefDataAccessor(() => this.gateway.getInvestigations());
  progressionTypes = new RefDataAccessor(() =>
    this.gateway.getProgressionTypes()
  );
  swellingTypes = new RefDataAccessor(() => this.gateway.getSwellingTypes());
  stages = new RefDataAccessor(() => this.gateway.getStages());
  irritabilityLevels = new RefDataAccessor(() =>
    this.gateway.getIrritabilityLevels()
  );
  natures = new RefDataAccessor(() => this.gateway.getNatures());
  dailyPatterns = new RefDataAccessor(() => this.gateway.getDailyPatterns());
  sleepPatterns = new RefDataAccessor(() => this.gateway.getSleepPatterns());
  aggravatingFactors = new RefDataAccessor(() =>
    this.gateway.getAggravatingFactors()
  );
  easingFactors = new RefDataAccessor(() => this.gateway.getEasingFactors());
  treatmentOptions = new RefDataAccessor(() =>
    this.gateway.getTreatmentOptions()
  );
  meridians = new RefDataAccessor(() => this.gateway.getMeridians());
  acupoints = new RefDataAccessor(() => this.gateway.getAcupoints());
  educationOptions = new RefDataAccessor(() =>
    this.gateway.getEducationOptions()
  );
  rxAnalysisOptions = new RefDataAccessor(() =>
    this.gateway.getRxAnalysisOptions()
  );

  dischargeStatus = new RefDataAccessor(() =>
    this.gateway.getDischargeStatus()
  );
  dischargers = new RefDataAccessor(() => this.gateway.getDischargers());
  dischargerOutcomes = new RefDataAccessor(() =>
    this.gateway.getDischargeOutcomes()
  );
  outcomesDetails = new RefDataAccessor(() => this.gateway.getOutcomeDetails());

  dominantHands = new RefDataAccessor(() => this.gateway.getDominantHands());

  sleepQualities = new RefDataAccessor(() => this.gateway.getSleepQualities());
  sleepPositions = new RefDataAccessor(() => this.gateway.getSleepPositions());
  sleepTimes = new RefDataAccessor(() => this.gateway.getSleepTimes());
  pulseRhythms = new RefDataAccessor(() => this.gateway.getPulseRhythms());
  bpPositions = new RefDataAccessor(() => this.gateway.getBPPositions());
  temperatureMethods = new RefDataAccessor(() =>
    this.gateway.getTemperatureMethods()
  );
  snoreOptions = new RefDataAccessor(() => this.gateway.getSnoreOptions());
  bodyAreas = new RefDataAccessor(() => this.gateway.getBodyAreas());

  onsets = new RefDataAccessor(() => this.gateway.getOnsets());
  durations = new RefDataAccessor(() => this.gateway.getDurations());
  frequencies = new RefDataAccessor(() => this.gateway.getFrequencies());
  frequencyOccurrences = new RefDataAccessor(() =>
    this.gateway.getFrequencyOccurrences()
  );
  impacts = new RefDataAccessor(() => this.gateway.getImpacts());
  hofPCAttributes = new RefDataAccessor(() =>
    this.gateway.getHofPCAttributes()
  );
  patientAppliedTreatments = new RefDataAccessor(() =>
    this.gateway.getPatientAppliedTreatments()
  );

  familyAliveStatuses = new RefDataAccessor(() =>
    this.gateway.getFamilyAliveStatuses()
  );

  specialTestHeadings = new RefDataAccessor(() =>
    this.gateway.getSpecialTestHeadings()
  );

  contraceptionTypes = new RefDataAccessor(() =>
    this.gateway.getContraceptionTypes()
  );

  severity = new RefDataAccessor(() => this.gateway.getSeverity());
  chronicity = new RefDataAccessor(() => this.gateway.getChronicity());
  fracture = new RefDataAccessor(() => this.gateway.getFracture());
  fractureTypes = new RefDataAccessor(() => this.gateway.getFractureTypes());
  bslQualifiers = new RefDataAccessor(() => this.gateway.getBSLQualifiers());
  bodyHabitus = new RefDataAccessor(() => this.gateway.getBodyHabitus());
  nails = new RefDataAccessor(() => this.gateway.getNails());
  hands = new RefDataAccessor(() => this.gateway.getHands());
  hydrations = new RefDataAccessor(() => this.gateway.getHydrations());
  pulseMethods = new RefDataAccessor(() => this.gateway.getPulseMethods());
  measurementTypes = new RefDataAccessor(() =>
    this.gateway.getMeasurementTypes()
  );
  observationTypes = new RefDataAccessor(() =>
    this.gateway.getObservationTypes()
  );
  cyanosisPatterns = new RefDataAccessor(() =>
    this.gateway.getCyanosisPatterns()
  );

  discolourations = new RefDataAccessor(() =>
    this.gateway.getDiscolourations()
  );

  pulseCharacteristics = new RefDataAccessor(() =>
    this.gateway.getPulseCharacteristics()
  );
}
