import { pickBy } from "lodash";
import { action, computed, observable } from "mobx";

import { ClinicalDataType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { AreasToObserveKeys } from "@shared-types/clinical/areas-to-observe-keys.type.ts";
import { DirtyAreasMap } from "@shared-types/clinical/dirty-area-map.type.ts";

import { getIsEqual, isEqualCustomizer } from "../utils/clinical.utils.ts";
import { EncounterClinicalData } from "./EncounterClinicalData.ts";

export class StashedEncounterClinicalData extends EncounterClinicalData {
  static ignoredKeys = [
    "eTag",
    "createLog",
    "changeLog",
    "updateLog",
    "isClosed",
    "reasonForClose",
    "claimId",
    "questionnaireItemId",
    "questionnaireCode",
    "questionnaireId",
    "id",
    "businessRoleCode",
    "psfsGoalsAdded",
    "isPrimaryDiagnosis",
    "timeStamp",
    "bodyArea",
    "nerve"
  ];

  @computed
  get dirtyAreas(): DirtyAreasMap | undefined {
    const map: DirtyAreasMap = new Map();
    if (this.dto) {
      for (const key in this.dto) {
        const original = this.originalDto ? this.originalDto[key] : undefined;

        const synced = this.dto[key];

        const isEqual = getIsEqual(
          original,
          synced,
          StashedEncounterClinicalData.ignoredKeys
        );
        map.set(key as AreasToObserveKeys, !isEqual);
      }
    }
    return map;
  }

  /**
   * For stashed data shared forms we need to track if one of the forms has been reset.
   * In this case, we do not want to use the stashed data for this form the next time we open one, but will use clinical data.
   * However, for related forms we still want to use stashed values.
   */
  @observable
  public haveBeenResetForms = new Map<ClinicalDataType, boolean>();

  @action
  public setHaveBeenResetForms = (type: ClinicalDataType, value: boolean) => {
    this.haveBeenResetForms.set(type, value);
  };

  @computed
  get hasSomeDirtyAreas(): boolean {
    return this.dirtyAreas
      ? Array.from(this.dirtyAreas.values()).some(i => i)
      : false;
  }

  @computed
  get dirtySOTAPForm(): boolean {
    if (this.dirtyAreas) {
      const isPhysicalActivityDirty =
        !!this.dirtyAreas?.get("physicalActivity");

      const isWorkHistoryDirty = !!this.dirtyAreas?.get("workHistory");
      const isPhysioMedicalHistoryDirty = !!this.dirtyAreas?.get(
        "physioMedicalHistory"
      );

      const isInjuryDirty = !!this.dirtyAreas?.get("injury");
      const isPhysioBodyDirty = !!this.dirtyAreas?.get("physioBody");
      const isTreatmentPlanDirty = !!this.dirtyAreas?.get(
        "patientTreatmentPlan"
      );

      const isCustomClinicalToolDirty =
        !!this.dirtyAreas?.get("customClinicalTool");

      const isDiagnosesDirty = !!this.dirtyAreas?.get("diagnoses");
      const isInjuryAreaDirty = !!this.dirtyAreas?.get("injuryArea");
      const isPatientDemographicUpdateDirty = !!this.dirtyAreas?.get(
        "patientDemographicUpdate"
      );

      const isReasonForVisitDirty = !!this.dirtyAreas?.get("reasonForVisit");

      const isCustomClinicalToolContextDirty = !!this.dirtyAreas?.get(
        "customClinicalToolContext"
      );

      const isCurrentInjuryDirty = !!this.dirtyAreas?.get("currentInjury");

      const isPostureOrObservationsDirty = !!this.dirtyAreas?.get(
        "postureOrObservations"
      );

      const isOtherMovementsDirty = !!this.dirtyAreas?.get("otherMovements");

      const isAnalysisAndPlanDirty = !!this.dirtyAreas?.get("analysisAndPlan");

      const isTreatmentAndEducationDirty = !!this.dirtyAreas?.get(
        "treatmentAndEducation"
      );

      const isGeneralExaminationDirty =
        !!this.dirtyAreas?.get("generalExamination");

      return (
        isPhysicalActivityDirty ||
        isWorkHistoryDirty ||
        isPhysioMedicalHistoryDirty ||
        isInjuryDirty ||
        isPhysioBodyDirty ||
        isTreatmentPlanDirty ||
        isCustomClinicalToolDirty ||
        isDiagnosesDirty ||
        isInjuryAreaDirty ||
        isPatientDemographicUpdateDirty ||
        isReasonForVisitDirty ||
        isCustomClinicalToolContextDirty ||
        isCurrentInjuryDirty ||
        isPostureOrObservationsDirty ||
        isOtherMovementsDirty ||
        isAnalysisAndPlanDirty ||
        isTreatmentAndEducationDirty ||
        isGeneralExaminationDirty
      );
    }

    return false;
  }

  @computed
  get hasAcc45DirtyAreas(): boolean {
    const isDateOfInjuryEqual = isEqualCustomizer(
      this.originalDto?.currentInjury?.dateOfInjury,
      this.dto.currentInjury?.dateOfInjury
    );

    const isMechanismOfInjuryEqual =
      this.originalDto?.currentInjury?.mechanismOfInjury ===
      this.dto.currentInjury?.mechanismOfInjury;

    const isDiagnosesEqual = getIsEqual(
      this.originalDto?.diagnoses,
      this.dto.diagnoses,
      StashedEncounterClinicalData.ignoredKeys
    );

    return (
      !isDateOfInjuryEqual || !isMechanismOfInjuryEqual || !isDiagnosesEqual
    );
  }

  @computed
  get dirtyGeneralExaminationForm() {
    return this.dirtyAreas
      ? !!this.dirtyAreas.get("generalExamination")
      : false;
  }

  @computed
  get dirtyCurrentInjuryForm() {
    return this.dirtyAreas ? !!this.dirtyAreas.get("currentInjury") : false;
  }

  @computed
  get dirtyInjuryForm() {
    return this.dirtyAreas ? !!this.dirtyAreas.get("injury") : false;
  }

  @computed
  get dirtyPhysioBodyForm() {
    return this.dirtyAreas ? !!this.dirtyAreas.get("physioBody") : false;
  }

  @computed
  get dirtyFamilyHistoryForm() {
    return this.dirtyAreas ? !!this.dirtyAreas.get("familyHistory") : false;
  }

  @computed
  get dirtyClinicalFlagsForm() {
    return this.dirtyAreas ? !!this.dirtyAreas.get("clinicalFlags") : false;
  }

  @computed
  get dirtySystemsReviewForm() {
    return this.dirtyAreas ? !!this.dirtyAreas.get("systemsReview") : false;
  }

  @computed
  get dirtyManagementForm() {
    return this.dirtyAreas
      ? !!this.dirtyAreas.get("patientTreatmentPlan")
      : false;
  }

  @computed
  get dirtyTreatmentForm() {
    return this.dirtyAreas
      ? !!this.dirtyAreas.get("treatmentAndEducation") ||
          !!this.dirtyAreas.get("analysisAndPlan")
      : false;
  }

  @computed
  get dirtySocialForm() {
    return this.dirtyAreas
      ? !!this.dirtyAreas.get("social") ||
          !!this.dirtyAreas.get("patientDemographicUpdate") ||
          this.dirtyAreas.get("physicalActivity")
      : false;
  }

  @computed
  get dirtyPhysicalActivityForm() {
    return this.dirtyAreas ? !!this.dirtyAreas.get("physicalActivity") : false;
  }

  @computed
  get dirtyWorkHistoryForm() {
    return this.dirtyAreas
      ? !!this.dirtyAreas.get("workHistory") ||
          !!this.dirtyAreas.get("patientDemographicUpdate")
      : false;
  }

  @computed
  get dirtyDischargeForm() {
    return this.dirtyAreas
      ? !!this.dirtyAreas.get("patientTreatmentPlan") ||
          !!this?.dirtyAreas.get("discharge")
      : false;
  }

  public resetStashedClinicalData = (
    areas: (keyof EncounterClinicalData)[]
  ) => {
    const payload = pickBy(this.originalDto, (_, key) => {
      return areas.includes(key as keyof EncounterClinicalData);
    });

    // look for not existing fields in payload after pickBy filtering and add them with undefined value
    // to match EncounterClinicalData model.
    areas.forEach(key => {
      if (!payload[key]) {
        payload[key] = undefined;
      }
    });

    this.shallowMergeFromDto(payload);
  };
}
