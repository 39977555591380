import { computed, observable, runInAction } from "mobx";

import { Entity } from "@libs/api/hub/Entity.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { IHubGateway } from "@libs/api/hub/HubGateway.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  FormDesignAddDTO,
  FormDesignUpdateDTO,
  FormGroupDTO
} from "@libs/gateways/form-design/FormDesignGateway.dtos.ts";
import { IFormDesignGateway } from "@libs/gateways/form-design/FormDesignGateway.interface.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { permission } from "@stores/decorators/permission.ts";
import type { Store } from "@stores/types/store.type.ts";

import { FormDesignUi } from "./FormDesignUi.ts";

export class FormDesignStore implements Store<FormDesignStore> {
  constructor(
    private gateway: IFormDesignGateway,
    private hub: IHubGateway
  ) {}

  ui = new FormDesignUi();
  root: IRootStore;

  formDesignInstanceMap = observable.map<string, FormDesignUpdateDTO>();

  private get notification() {
    return this.root.notification;
  }

  @computed
  get formDesigns() {
    return Array.from(this.formDesignInstanceMap.values());
  }

  afterAttachRoot() {
    this.hub.onEntityEvent(Entity.FormDesign, async event => {
      if (event.action === EventAction.Delete) {
        const doomed = this.formDesignInstanceMap.get(event.id);
        if (doomed) {
          runInAction(() => {
            this.formDesignInstanceMap.delete(event.id);
          });
        }
      } else {
        const changed = this.formDesignInstanceMap.get(event.id);
        if (!changed || changed.eTag !== event.etag) {
          const formDesign = await this.getFormDesign(event.id);
          runInAction(() => {
            formDesign &&
              this.formDesignInstanceMap.set(formDesign.id, formDesign);
          });
        }
      }
    });
  }

  @permission([Permission.FormDesignWrite])
  async togglePublish(formDesign: FormDesignUpdateDTO) {
    try {
      if (formDesign.isPublished) {
        await this.gateway.unPublishForm(formDesign.id);
      } else {
        await this.gateway.publishForm(formDesign.id);
      }
      this.getFormDesigns();
    } catch (error) {
      this.notification.error(error, {
        messageOverride:
          "An error occurred while publishing/unpublishing form design"
      });
      throw error;
    }
  }
  async setFormTypeCode(id: string, formTypeCode: string) {
    try {
      await this.gateway.setFormTypeCode(id, formTypeCode);
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred while loading form designs"
      });
    }
  }
  async getFormDesigns(): Promise<FormDesignUpdateDTO[]> {
    try {
      const formDesigns = await this.gateway.getFormDesigns();
      runInAction(() => {
        formDesigns.map(formDesign =>
          this.formDesignInstanceMap.set(formDesign.id, formDesign)
        );
      });
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred while loading form designs"
      });
      throw error;
    }
    return Array.from(this.formDesignInstanceMap.values());
  }

  async createFormDesign(
    design: FormDesignAddDTO
  ): Promise<FormDesignUpdateDTO> {
    try {
      const {
        description,
        groups,
        isPublished,
        name,
        title,
        processingKeysJson,
        isClinical,
        contextJson,
        notificationSetting
      } = design;
      return this.gateway.createFormDesign({
        processingKeysJson,
        contextJson,
        isClinical,
        description,
        groups,
        isPublished,
        title,
        name,
        notificationSetting
      });
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred while loading form designs"
      });
      throw error;
    }
  }

  async saveFormDesign(
    design: FormDesignUpdateDTO
  ): Promise<FormDesignUpdateDTO | undefined> {
    try {
      if (!design.id) {
        const {
          description,
          groups,
          isPublished,
          name,
          title,
          notificationSetting
        } = design;
        return this.gateway.createFormDesign({
          description,
          groups,
          isPublished,
          name,
          title,
          notificationSetting
        });
      } else {
        const {
          description,
          groups,
          isPublished,
          name,
          title,
          id,
          eTag,
          isClinical,
          notificationSetting,
          domainCode
        } = design;
        return this.gateway.updateFormDesign({
          id,
          eTag,
          description,
          groups,
          isPublished,
          isClinical,
          name,
          title,
          notificationSetting,
          domainCode
        });
      }
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred while loading form designs"
      });
    }
    return undefined;
  }

  async getFormDesign(id: string): Promise<FormDesignUpdateDTO | undefined> {
    try {
      const dto = await this.gateway.getFormDesign(id);
      return {
        ...dto,
        groups: Array.from(
          dto.groups.sort((a, b) => (a?.sortOrder || 0) - (b?.sortOrder || 0))
        )
      };
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred while loading a form design"
      });
    }
    return undefined;
  }

  async deleteFormDesign(id: string): Promise<string | undefined> {
    try {
      await this.gateway.deleteFormDesign(id);
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred while deleting a form design"
      });
    }
    return undefined;
  }
  async getFormDesignGroups(): Promise<FormGroupDTO[]> {
    try {
      return await this.gateway.getFormDesignGroups();
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred while loading form designs"
      });
    }
    return [];
  }
}
